import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import axios from "axios";
import Cookies from 'js-cookie';
import {ThemeProvider, Typography, Container} from '@mui/material';
import { ThemeCustom} from "../styles/Theme";
import { useUserContext } from '../context/User_Access';
import { useBaseUrlContext } from '../context/Base_url';
import NavBarSimple from '../components/nav/Nav_simple';

import ButtonSecondary from '../components/button/Btn_secondary';

import docIcon from '../assets/logo-icon.png'
import { Navigate } from 'react-router';

const WelcomeFlow = () => {

    const navigate = useNavigate();
    const [cookieOptions, setCookieOptions] = useState({})
    const {setUserDetails} = useUserContext()
    const location = useLocation();
    const [disable, setDisable] = useState(true)
    const {BaseUrl, env} = useBaseUrlContext()
    const {devMode} = useUserContext()


    useEffect(()=>{

        const domain = window.location.hostname
        //const baseDomain = domain.split('.').slice(-2).join('.');
        //const cookieDomain = domain === 'localhost' ? 'localhost' : `.${baseDomain}`;
        const isSecure = domain === 'localhost' ? false : domain.includes('invoiceai.local') ? false : true;

        setCookieOptions(
            {
                path: '/',
                secure: isSecure, 
                sameSite: 'lax', // Use 'lax' to balance security and accessibility
                //domain: cookieDomain,
            }
        )

        const searchParams = new URLSearchParams(location.search);
        const getTokenKey = searchParams.get('key')
        console.log('getTokenKey', typeof(getTokenKey))
        if(getTokenKey){
            
            tokenRenew(getTokenKey)

        }
        
    },[])

    const tokenRenew = async (refreshToken) => {

        console.log('BaseUrl ', BaseUrl)
    
        try {

            const res = await axios.post(`${BaseUrl}/login`, { refreshToken });

            let data = res.data.result;
            let error = res.data.error;
            console.log('res', data.authorization, error.text);

            if (!error.text && !error.code) {

                Cookies.set('token', data.authorization.token, cookieOptions);
                Cookies.set('expire', data.authorization.expiresAt, cookieOptions);
                Cookies.set('refreshToken', data.authorization.refreshToken, cookieOptions);
                console.log('Token renewed successfully.', location.pathname);
                setUserDetails(data.profile.name, data.profile.email, data.profile.id, JSON.stringify(data.permissions), data.organization.name, data.organization.logo)
                Cookies.set('access', JSON.stringify(data.permissions), cookieOptions);
                Cookies.set('userName', data.profile.name, cookieOptions);
                Cookies.set('userEmail', data.profile.email, cookieOptions);
                Cookies.set('userId', data.profile.id, cookieOptions);
                Cookies.set('auth0Id', data.profile.auth0Id, cookieOptions);
                Cookies.set('auth0Connection', data.profile.auth0Connection, cookieOptions);
                Cookies.set('packageId', data.package.id, cookieOptions);
                Cookies.set('orgName', data.organization.name, cookieOptions);
                Cookies.set('orgLogo', data.organization.logo, cookieOptions);
                if(data.preferences && Object.keys(data.preferences).length > 0){
                    localStorage.setItem('regionFilter', JSON.stringify(data.preferences.documentReview.regions))
                    localStorage.setItem('customCol', JSON.stringify(data.preferences.documentReview.selectedColumns))
                }
                console.log('data.permissions', data.permissions)
                return true;

            } else {

                console.log('Token renewal error:', error.text);
                return false;

            }

        } catch (err) {

            console.error('Error during token renewal:', err);
            return false;

        }
    }


    return(
        <ThemeProvider theme={ThemeCustom}>
            <NavBarSimple isUserLogged={true} hasTenant={true}/>
            <Container maxWidth="false">
                <div className="h-screen flex justify-center items-center flex-col py-14 relative">
                    <img className="max-w-[6.25rem] min-h-[6.25rem] mb-8" src={docIcon} alt="logo"/>
                    <Typography variant="text60" className="mb-4 text-center" component='div'>
                        Welcome to Docupath
                    </Typography>
                    <Typography variant="text20" className="mb-14 text-center" component='div'>
                        Docupath let's you transform business documents in a whole new way.
                    </Typography>
                    <div className='' >
                        <ButtonSecondary variant={'secondaryBtn'} type='button' btnName='Get Started' classes="w-full sm:w-[30rem] w-[20rem]" onClick={()=>{devMode ? navigate('/pick_your_package') : navigate('/lets_go')}} disabled={false}/>
                    </div>
                    {/* <div className='absolute bottom-16 flex justify-between'>
                        <div className='w-2 h-2 bg-secondary rounded-full'></div>
                        <div className='w-2 h-2 bg-borderGrayDark rounded-full mx-4'></div>
                        <div className='w-2 h-2 bg-borderGrayDark rounded-full'></div>
                    </div> */}
                </div>
            </Container>
        </ThemeProvider>
    )

}

export default WelcomeFlow;