import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import axios from "axios";
import Cookies from 'js-cookie';
import SimpleBar from 'simplebar-react';
import { useBaseUrlContext } from '../../context/Base_url';
import { useAuthContext } from '../../context/Auth';
import useAxiosInstance from '../../api/interceptor';

import {Box, Typography, Modal, FormControl, OutlinedInput, InputLabel, InputAdornment} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';
import SklInputPopup from '../skeletons/Skl_input_popup';
import ButtonSecondaryLoader from '../button/Btn_secondary_loader';

import ModalStyle from './Modal_styles.module.scss';
import './Modal_styles.scss';
import 'simplebar-react/dist/simplebar.min.css';

const OrgSettingsMainModal = ({openOrgMainSetting, setOpenOrgMainSetting, popupLoader, editId, orgEditData, setOrgEditData, inboxDomain, getOrgData, setOpenFail, setTextFail, setOpenSuccess, setTextSuccess}) =>{

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
    const [uploading, setUploading] = useState(false)
    const [orgFieldsMain, setOrgFieldsMain] = useState({})
    const {BaseUrl} = useBaseUrlContext()
    const {tokenReview, popupAction} = useAuthContext()
    const axiosInstance = useAxiosInstance();
    const [btnLoading, setBtnLoading] = useState(false)

    useEffect(()=>{console.log('tokenReview region add popup',tokenReview)},[tokenReview])

    useEffect(()=>{console.log('editOrgData*** org main',orgEditData)},[orgEditData])


    useEffect(()=>{
        if(openOrgMainSetting){
            setUploading(false)
        }
    },[openOrgMainSetting])

    //cancel

    const cancel = () =>{

        setOpenOrgMainSetting(false)
        reset({orgNameNew:"", attachmentEmail:""})
        setOrgFieldsMain({})
        setOrgEditData([])
        
    }

    //on submit function

    const onSubmitMain = (data) => {

        console.log('onSubmit', data)

        let emailFull = `${data.attachmentEmail}${inboxDomain}`;
        data.attachmentEmail = emailFull;
        const {attachmentEmail, orgNameNew, isMainOrganization}  = data

        if(editId === 0){
            
            const dataObj = {attachmentEmail, orgName:orgNameNew}
            console.log('submit data', dataObj)
            dataPost(dataObj);
            console.log('on submit main org', dataObj)

        }
        else{

            const dataObj = {attachmentEmail, orgName:orgNameNew, isMainOrganization, orgId:editId}
            console.log('submit data', dataObj)
            dataUpdate(dataObj);
            console.log('on submit edit main org', dataObj)

        }

    }

    //new data post

    const dataPost = async(data) => {

        setBtnLoading(true)

        try {

            const res = await axiosInstance.post(`/organization`, data)
            //console.log(res)
            let result = res.data.result;
            let error = res.data.error;

            console.log(result.message)
            setUploading(false)
            setOpenOrgMainSetting(false)
            getOrgData()
            setOpenSuccess(true)
            setTextSuccess(result.message)
            reset({orgNameNew:"", attachmentEmail:""})
            setOrgEditData([])
            setOrgFieldsMain({})
            setBtnLoading(false)

        } catch (err) {
            console.error('fetchData:', err);
            setBtnLoading(false)
            if (!err?.response?.alertShown) {
                setOpenFail(true)
                err?.response?.data?.error.text ? setTextFail(err.response.data.error.text): setTextFail('The server encounted an error and could not complete your request.')
            }
        } 

    }

    //data update

    const dataUpdate = async(data) => {

        console.log('data update')
        setBtnLoading(true)

        try {

            const res = await axiosInstance.put(`/organization`, data)

            let result = res.data.result;
            let error = res.data.error;

            console.log(result.message)
            setUploading(false)
            setOpenOrgMainSetting(false)
            getOrgData()
            setOpenSuccess(true)
            setTextSuccess(result.message)
            reset({orgNameNew:"", attachmentEmail:""})
            setOrgEditData([])
            setOrgFieldsMain({})
            setBtnLoading(false)

        } catch (err) {
            console.error('fetchData:', err);
            setBtnLoading(false)
            if (!err?.response?.alertShown) {
                setOpenFail(true)
                err?.response?.data?.error.text ? setTextFail(err.response.data.error.text): setTextFail('The server encounted an error and could not complete your request.')
            }
        }     

    }


    //separate the edit obj

    useEffect(()=>{

        if(orgEditData && editId !== 0){
            const {...other} = orgEditData;
            setOrgFieldsMain(other)
            console.log('orgEditData separate', other )
        }    

    },[orgEditData, editId])

    //form edit values set

    useEffect(()=>{

        console.log('orgFieldsMain', orgFieldsMain)

        if(openOrgMainSetting){

            Object.entries(orgFieldsMain).map(([key, value]) => {
                console.log('orgFieldsMain', key, value)
                if(key === 'attachmentEmail' && editId !== 0){
                    console.log('value xxx', value)
                    const subDomainEmail = value.split('-')[0]
                    setValue(key,subDomainEmail)
                }
                else if(key === 'orgName' && editId !== 0){
                    console.log('value xxx', value)
                    setValue('orgNameNew',value)
                }
            } )

        }


    },[orgFieldsMain])


    const setInputVal = (val, name) => {

    }


    return(

        <div>
            <Modal
                open={openOrgMainSetting}
                aria-labelledby="modal-modal-main-organizations-create"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-org-setting']}`}> 
                    <form onSubmit={handleSubmit(onSubmitMain)}>
                        <div className={ModalStyle['modal-container']}>
                            <div className={ModalStyle['content-wrapper']}>
                                <Typography id="modal-modal-title" align='center' className={`${ModalStyle['modal-Header1']} modal-mb-5`} variant="text24" component="h2">
                                    {editId === 0 ? 'Add new organization' :  'Edit organization settings'} 
                                </Typography>
                                <SimpleBar className={`min-h-organization-main-modal`}>
                                    <FormControl variant="standard" fullWidth  margin="normal" className='modal-mt-2 form-control modal-mb-5' >
                                        <InputLabel shrink htmlFor='org-orgNameNew' title='text' className='modal-label'>
                                            Organization name <span className='mand-mark'>*</span>
                                        </InputLabel>
                                        {popupLoader ? (
                                            <SklInputPopup/>
                                        ):(
                                        <>
                                            <OutlinedInput 
                                                id='org-orgNameNew'
                                                placeholder='Enter organization name'
                                                //value={orgVal.orgNameNew}
                                                autoComplete="off" 
                                                type='text'
                                                className='modal-input'
                                                {...register('orgNameNew',{ required: true })} 
                                                // {...register('orgNameNew',{ required: true , onChange: (e) => setInputVal(e.target.value, 'orgNameNew') })} 
                                            />
                                            {errors.orgNameNew && <p className='err-msg'>Organization name is required</p>}
                                        </>
                                        )}
                                    </FormControl>
                                    <FormControl variant="standard" fullWidth  margin="normal" className='modal-mt-2 form-control modal-mb-5' >
                                        <InputLabel shrink htmlFor='org-inboxEmail' title='text' className='modal-label'>
                                            Inbox to email attachments <span className='mand-mark'>*</span>
                                        </InputLabel>
                                        {popupLoader ? (
                                            <SklInputPopup/>
                                        ):(
                                        <>
                                        <OutlinedInput 
                                            id='org-inboxEmail'
                                            placeholder='Enter email'
                                            //value={isSubOrg === 1 ? orgVal.inboxEmail : inboxDomain}
                                            endAdornment={ (<InputAdornment position="end" className='domain-adornment'>{`${inboxDomain}`}</InputAdornment>) }
                                            autoComplete="off" 
                                            type='text'
                                            readOnly={editId === 0 ? false : true}
                                            className='modal-input'
                                            {...register('attachmentEmail',{ required: editId === 0 ? true:false})} 
                                            // {...register('attachmentEmail',{ required: editId === 0 ? true:false , onChange: (e) => {setInputVal(`${e.target.value}${inboxDomain}`, 'inboxEmail'); console.log(`${e.target.value}${inboxDomain}`, 'inboxEmail') }})} 
                                            
                                        />
                                        {errors.attachmentEmail && <p className='err-msg'>Email is required</p>}
                                        </>
                                        )}
                                    </FormControl>
                                </SimpleBar>    
 
                            </div>
                            <div className={`${ModalStyle['footer-wrapper-center']}  modal-mt-5`}>
                                <ButtonSecondary btnName= "Cancel" variant={'primaryBtn'} classes='modal-button-12' type="button" onClick={cancel} disabled={uploading}/>
                                {/* <ButtonSecondary btnName='Finish' variant={'secondaryBtn'} classes='modal-button-12' type="submit" onClick={()=>{}} disabled={popupLoader || uploading}/> */}
                                <ButtonSecondaryLoader variant={'secondaryBtn'} type='submit' classes='modal-button-12' btnName='Finish' btnLoading={btnLoading} disabled={popupLoader || uploading} onClick={()=>{}}/>
                            </div>
                        </div>    
                    </form>
                </Box>
            </Modal>
        </div> 
        
    );
}

export default OrgSettingsMainModal;