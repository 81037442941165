import React, {useEffect, useState} from 'react';
import {Link, useNavigate } from 'react-router-dom';

import {ThemeProvider, Container} from '@mui/material';
import { ThemeCustom} from "../styles/Theme";

import ButtonSecondary from '../components/button/Btn_secondary';

import Icon404 from '../assets/404.png'

const NotFound = (props) => {

    const navigate = useNavigate()

    return(
        <ThemeProvider theme={ThemeCustom}>
            <Container maxWidth="xl" >
                <div className="h-screen flex justify-center items-center flex-col">
                    <img src={Icon404} alt='exit icon' className='max-w-[18rem] w-full mb-8'/>
                    <div className="text-6xl font-sans font-semibold mb-5">Page Not Foundx</div> 
                    <div className="text-xl font-sans font-medium  mb-[3.5rem]">Oops! You've reached a blank spot. This page isn't available, Let's get you back on track!</div>
                    <ButtonSecondary btnName="Back to Homepage"  classes={'w-full max-w-[20rem]'} variant={'primaryBtn'} type="button" onClick={() => navigate('/file_upload')} disabled={false}/>
                </div>
            </Container>
        </ThemeProvider>
    )
}

export default NotFound;