import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import Cookies from 'js-cookie';
import { useBaseUrlContext } from '../context/Base_url';
import { useUserContext } from '../context/User_Access';

import {ThemeProvider, Typography} from '@mui/material';
import { ThemeCustom} from "../styles/Theme";
import ErrorAlert from '../components/alerts/Fail_alert';

import InvoiceLogo from '../assets/Docupath-Logo.svg'
import loaderIcon from '../assets/Loading1.svg'


const Redirect = (props) => {

    const {BaseUrl, env} = useBaseUrlContext()
    //const { user, isAuthenticated, isLoading, logout } = useAuth0();
    const navigate = useNavigate();
    const {setUserDetails} = useUserContext()
    const [cookieOptions, setCookieOptions] = useState({})
    const [openFail, setOpenFail] = useState(false);
    const [textFail, setTextFail] = useState(''); 
    const location = useLocation();
    const { logout } = useAuth0();

    //error alert

    useEffect(()=>{

        setTimeout(()=>{
            setOpenFail(false);
        },10000)

    },[openFail]);

    //has subdomain

    useEffect(()=>{

        const hostname = window.location.hostname;
        const parts = hostname.split('.');
        
        if(!hostname.includes('localhost')){
          
            if(parts.length > 2 ){
                sessionStorage.setItem('subDomain', parts.at(0))
            }
            else{
                sessionStorage.setItem('subDomain', '')
            }

        }
        else{
            sessionStorage.setItem('subDomain', '')
        }

    },[])
    
    useEffect(()=>{

        const domain = window.location.hostname
        //const baseDomain = domain.split('.').slice(-2).join('.');
        //const cookieDomain = domain === 'localhost' ? 'localhost' : `.${baseDomain}`;
        const isSecure = domain === 'localhost' ? false : domain.includes('invoiceai.local') ? false : true;

        setCookieOptions(
            {
                path: '/',
                secure: isSecure, 
                sameSite: 'lax', // Use 'lax' to balance security and accessibility
                //domain: cookieDomain,
            }
        )
        
    },[])

    // useEffect(()=>{

    //     console.log('user create work', user)
    //     if(user){
    //         sessionStorage.setItem('authSessionLogin', JSON.stringify(user))
    //         if(user){
    //             onSubmit(user)
    //         }
    //         else{
    //            const getUserData =  JSON.parse(sessionStorage.getItem('authSessionLogin'))
    //             if(getUserData){
    //                 onSubmit(getUserData)
    //             }
    //         }
    //     }
        
    // },[user, isAuthenticated, isLoading])

    useEffect(()=>{onSubmit()},[])


    const onSubmit = (data) => {

        const searchParams = new URLSearchParams(location.search);
        const getCode = searchParams.get('code')

        if(getCode == 0 ||  getCode == 1 || getCode == 2 || getCode == 3 || getCode == 4 ){

            navigate('/login_unsuccessful')

        }
        else{

            axios
                .post(`${BaseUrl}/login`,{

                    //"username": data.email,
                    "accessCode": getCode

                })
                .then((res)=>{
                    let data = res.data.result;
                    let error = res.data.error;
                    //console.log('res', data.accessToken, error.text);
                    if(error && error.text === '' &&  error.code === ''){
                        //window.location.href = window.location.origin;
                        Cookies.set('token', data.authorization.token, cookieOptions);
                        Cookies.set('expire', data.authorization.expiresAt, cookieOptions);
                        Cookies.set('refreshToken', data.authorization.refreshToken, cookieOptions);
                        setUserDetails(data.profile.name, data.profile.email, data.profile.id, JSON.stringify(data.permissions), data.organization.name, data.organization.logo)
                        Cookies.set('access', JSON.stringify(data.permissions), cookieOptions);
                        Cookies.set('userName', data.profile.name, cookieOptions);
                        Cookies.set('userEmail', data.profile.email, cookieOptions);
                        Cookies.set('userId', data.profile.id, cookieOptions);
                        Cookies.set('auth0Id', data.profile.auth0Id, cookieOptions);
                        Cookies.set('auth0Connection', data.profile.auth0Connection, cookieOptions);
                        Cookies.set('packageId', data.package.id, cookieOptions);
                        Cookies.set('orgName', data.organization.name, cookieOptions);
                        Cookies.set('orgLogo', data.organization.logo, cookieOptions);
                        if(data.preferences && Object.keys(data.preferences).length > 0){
                            localStorage.setItem('regionFilter', JSON.stringify(data.preferences.documentReview.regions))
                            localStorage.setItem('customCol', JSON.stringify(data.preferences.documentReview.selectedColumns))
                        }
                        navigate('/file_upload');
                        console.log('success login xx', data.profile.name, data.profile.id, data.permissions);
                    }
                    else{
                        setOpenFail(true)
                        setTextFail(error.text)
                        console.log(error.text);
                        //alert(error.text);
                        navigate('/login_unsuccessful')

                    }
                })
                .catch((err)=>{
                    console.log('err', err);
                    setOpenFail(true)
                    //alert('The server encounted an error and could not complete your request.', err);
                    err.response?.data?.error?.text ? setTextFail(err.response.data.error.text):setTextFail('The server encounted an error and could not complete your request.')
                    setTimeout(()=>{
                        logout({ 
                            logoutParams: { returnTo: `${window.location.origin}/` } 
                        })
                    },2500)
                });

        }
       
    }  

    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className='absolute top-8 right-8 w-full z-[1999] h-0'>
                <ErrorAlert setOpenFail={setOpenFail} openFail={openFail} alretText={textFail}/>
            </div>
            <div className={`${props.class? props.class:'h-screen'} flex justify-center items-center flex-col`}>
                <div className='flex flex-col items-center justify-center'>
                    <img className="max-w-[2.5rem] min-h-[2.5rem]" src={loaderIcon} alt="loading logo"/>
                    <Typography variant="text24" className="!font-medium mt-7" component='div'>
                        Please wait...
                    </Typography>
                </div>
            </div>
        </ThemeProvider>
    )
}

export default Redirect;