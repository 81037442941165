import React, {useState, useEffect, useRef} from 'react';
import SimpleBar from 'simplebar-react';
import {useSearchParams, useNavigate  } from 'react-router-dom';
import axios from "axios";
import Cookies from 'js-cookie';
import { useBaseUrlContext } from '../context/Base_url';
import { useUserContext } from '../context/User_Access';
import { useAuthContext } from '../context/Auth';
import useAxiosInstance from '../api/interceptor';

import {ThemeProvider, Container, Typography, FormControl, OutlinedInput, InputAdornment } from '@mui/material';

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";

import DragDrop from '../components/file-upload/Drag-input';
import SuccessAlert from '../components/alerts/Success_alert';
import ErrorAlert from '../components/alerts/Fail_alert';
import ButtonOutlineImg from '../components/button/Btn_outline_img';
import SklButton from '../components/skeletons/Skl_button';
import SklHeaderText24 from '../components/skeletons/Skl_header-text24';
import SklInput from '../components/skeletons/Skl_input';

import FilePreview from '../components/file-upload/File_preview';
import { ThemeCustom} from "./../styles/Theme";
import '../components/file-upload/Drag-input_styles.scss';

import SearchIcon from '../assets/search.svg'
import importIcon from '../assets/upload.svg'
import recSerach from '../assets/receipt-search.svg'


const InvoiceUploader = (props) =>{
    const [files, setFiles] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openFail, setOpenFail] = useState(false);
    const [textSuccess, setTextSuccess] = useState('');
    const [textFail, setTextFail] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState('');
    const [loader, setLoader] = useState(true);
    const [hasDoc, sethasDoc] = useState(false);
    const navigate = useNavigate();
    const [sklReviewBtn, setSklReviewBtn] = useState(true);
    const [sklCommon, setSklCommon] = useState(true);
    const [sklFilePreview, setSklFilePreview] = useState(true);
    const {BaseUrl} = useBaseUrlContext()
    const {devMode} = useUserContext()
    const {tokenReview, unAuthAccess, popupAction} = useAuthContext()
    const axiosInstance = useAxiosInstance();

    //page skeleton handiling

    useEffect(() => {
    
        getValidateButton()

        setTimeout(()=>{
           setSklCommon(false)
        },200)

    },[]) 

    //unAuthAccess

    useEffect(() => {
        console.log('navigate xxx');
        unAuthAccess()
    }, [navigate]);

    useEffect(()=>{
        setTimeout(()=>{
            setOpenFail(false);
        },10000)

        setTimeout(()=>{
            searchParams.delete('err')
            setSearchParams(searchParams)
        },1000)

    },[openFail]);

    useEffect(()=>{
        console.log(openSuccess)
        setTimeout(()=>{
            setOpenSuccess(false);
        },10000)
    },[openSuccess]);   

    useEffect(()=>{

        console.log(searchParams)
        if(searchParams.get('err')) {

            setOpenFail(true);
            setTextFail('Something went wrong. Please try again.') 
        }


    },[])  

    useEffect(() => {
        if(disabled){
            if(files.length > 0){
                setDisabled(false);
                //console.log('xxx files ena disab', files);
                setTimeout(()=>{
                    setLoader(false)
                },1000)
                
            }
            else{
                setDisabled(true);
                setLoader(true)
                setSklFilePreview(true)
            }
        }
        console.log('xxx files', files)
         
    },[files]);

    const goToReview = () => {

        if(devMode){
            navigate('/document_review?devMode=true');
        }
        else{
            navigate('/document_review');
        }
        
    }

    //get validation data

    const getValidateButton = async() =>{

        try {

            setSklReviewBtn(true)

            const res = await axiosInstance.get(`/list_files?documentCheck=1`)
            
            let data = res.data.result;
            let error = res.data.error;
                
            console.log('reportsData', data.documentCheck)
            sethasDoc(data.documentCheck)
            setSklReviewBtn(false)

        } catch (err) {
            console.error('fetchData:', err);
        }     

    }

    //has subdomain

    useEffect(()=>{

        const hostname = window.location.hostname;
        const parts = hostname.split('.');
        
        if(!hostname.includes('localhost')){
          
            if(parts.length > 2 ){
                sessionStorage.setItem('subDomain', parts.at(0))
            }
            else{
                sessionStorage.setItem('subDomain', '')
            }
        }
        else{
            sessionStorage.setItem('subDomain', '')
        }

    },[])


    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className='absolute top-8 right-8 w-full z-[1999] h-0'>
                <SuccessAlert setOpenSuccess={setOpenSuccess} openSuccess={openSuccess} alretText={textSuccess}/>
                <ErrorAlert setOpenFail={setOpenFail} openFail={openFail} alretText={textFail}/>
            </div>
            <div className='mt-[4rem] container-up-margin'>
                <Container maxWidth="false">
                    <div className='flex justify-between items-center'>
                        {sklCommon?(
                            <SklHeaderText24/>
                        ):(
                            <Typography variant="text24" className="font-medium py-8" component='div'>
                                Upload your business documents
                            </Typography>
                        )}
                        {!disabled ? (

                        <div className='flex items-center'>
                            <FormControl variant="standard"  margin="normal" className='m-0 w-full lg:min-w-[25rem] mr-4'>
                                {sklFilePreview?(
                                    <SklInput/>
                                ):(
                                    <OutlinedInput className='search-input rounded-xl'
                                        autoComplete="off" 
                                        placeholder="Search" 
                                        type='text'
                                        value={searchTerm}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <img src={SearchIcon} alt='icon' className='w-5'/>
                                            </InputAdornment>
                                        }
                                        onChange={(e) => {setSearchTerm(e.target.value)}}
                                    />
                                )}
                            </FormControl>
                            {sklFilePreview?(
                                <SklButton/>
                            ):(      
                                <FilePond
                                    className='filepond-btn'
                                    files={files}
                                    allowReorder={false}
                                    allowMultiple={true}
                                    onupdatefiles={setFiles}
                                    credits={false}
                                    labelIdle={`<div class="drag-text-wrapper"><img src='${importIcon}' class="me-3 max-w-[1.5rem]"/>Upload More</div>`}                        
                                />
                            )} 
                        </div>
                        ):(
                            sklReviewBtn ? (
                                <SklButton/>
                            ):(
                                hasDoc ? <ButtonOutlineImg btnName="Go to Review"  variant={'primaryOutline'} img={recSerach} type="submit" onClick={goToReview} disabled={false}/> : ''

                            )
                        )}
                    </div>
                    {disabled ? (
                    <div className={`mb-5 rounded-xl`}>
                        <SimpleBar className={`min-height-upload`}>
                            <div className='flex justify-center items-center'>
                                    <DragDrop 
                                        setOpenSuccess={setOpenSuccess} 
                                        setTextSuccess={setTextSuccess} 
                                        setOpenFail={setOpenFail} 
                                        setTextFail={setTextFail} 
                                        setSearchParams={setSearchParams} 
                                        searchParams={searchParams}
                                        files={files}
                                        setFiles={setFiles}
                                        sklCommon={sklCommon}
                                    />
                            </div>
                    </SimpleBar>
                    </div>
                    ):(
    
                        <FilePreview 
                            loader={loader} 
                            disabled={disabled} 
                            setDisabled={setDisabled} 
                            fileArray={files} 
                            setFiles={setFiles} 
                            searchTerm={searchTerm} 
                            setSearchTerm={setSearchTerm} 
                            setUploading={props.setUploading} 
                            setSubmitFiles={props.setSubmitFiles} 
                            setUploadFiles={props.setUploadFiles} 
                            setUploadCompeted={props.setUploadCompeted}
                            devMode={devMode}
                            setOpenSuccess={setOpenSuccess} 
                            setTextSuccess={setTextSuccess} 
                            setOpenFail={setOpenFail} 
                            setTextFail={setTextFail} 
                            setSklFilePreview={setSklFilePreview}
                            sklFilePreview={sklFilePreview}
                        />
                    )
                }
                </Container> 
            </div>
        </ThemeProvider>
    );
}

export default InvoiceUploader;