import React, {useState, useEffect, useRef} from 'react';
import { useBaseUrlContext } from '../../context/Base_url';
import axios from "axios";
import Cookies from 'js-cookie';
import SimpleBar from 'simplebar-react';
import { useOutletContext } from 'react-router-dom';
import { useAuthContext } from '../../context/Auth';
import useAxiosInstance from '../../api/interceptor';

import {ThemeProvider, OutlinedInput, Select , FormControl, MenuItem, InputAdornment} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { ThemeCustom} from "../../styles/Theme";
import 'simplebar-react/dist/simplebar.min.css';
import './settings_styles.scss';

import SearchIcon from '../../assets/search.svg'

import MappingUploadModal from '../modal/Modal_mapping_upload';
import XmlEditViewer from './XML_editable_viewer';
import SettingsFooter from '../nav/Settings_footer';
import VariableList from './Variable_list';
import SklInput from '../skeletons/Skl_input';
import SklXmlViewer from '../skeletons/Skl_xmlviewer';
import SklVariableList from '../skeletons/Skl_variable_list';

const KnowMap = ({openFail, textFail, openSuccess, textSuccess, OpenWarning, textWarning}) =>{

    const [destinationFormat, setDestinationFormat] = useState('ubl_2.1');
    const [knownMap, setKnownMap] = useState([]);
    const [filter, setFilter] = useState('');
    const [filterFileType, setFilterFileType] = useState('');
    const [filterTypeImport, setFilterTypeImport] = useState([]);
    const [filterImport, setFilterImport] = useState([]);
    const [openMapUpload, setOpenMapUpload] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [variables, setVariables] = useState([]);
    const [filteredData, setFilteredData] = useState('');
    const [variablesSet, setvariablesSet] = useState([]);
    const [lastCursorPosition, setLastCursorPosition] = useState(null);
    const [clickId, setClickId] = useState()
    const [variableList, setVariableList] = useState([])
    //const [xmlString, setXmlString] = useState('')
    const [loaderSelect, setLoaderSelect] = useState(true);
    const [loaderView, setLoaderView] = useState(true);
    const {BaseUrl} = useBaseUrlContext()
    const {tokenReview, popupAction} = useAuthContext()
    const axiosInstance = useAxiosInstance();

    const {setOpenFail} = useOutletContext(); 
    const {setTextFail} = useOutletContext(); 
    const {setOpenSuccess} = useOutletContext(); 
    const {setTextSuccess} = useOutletContext(); 
    const {setOpenWarning} = useOutletContext(); 
    const {setTextWarning} = useOutletContext(); 


    useEffect(()=>{console.log('filter xxxxxxxxxxxxxxxxxxxxxxxxxxxx',filter) },[filter])


    useEffect(() => {
       
        //format type
        formatApi();

    },[])   

    //variable load

    const getVariableAPI = async () => {

        try {

            if(((filter !== '' && filter != 0) || filterFileType !== '')){

                setLoaderView(true)

                const res = await axiosInstance.get(`/known_mappings?categoryId=${filter}&&documentType=${filterFileType}`)

                let data = res.data.result;
                let error = res.data.error;

                console.log('response known_mappings',data.invoiceVariables, data.destinationFormat);
                setVariableList(data.invoiceVariables);
                setvariablesSet(data.invoiceVariables);
                setFilteredData(data.destinationFormat);
                setLoaderView(false)

            }

        } catch (err) {
            console.error('fetchData:', err);
        } 

    }

    useEffect(() => {
        const newFilteredData = variableList.map(item => {
          const filteredItem = { key: item.key };
          Object.keys(item).forEach(subKey => {
            if (subKey.toLowerCase().includes(searchTerm.toLowerCase()) && subKey !== 'key') {
              filteredItem[subKey] = item[subKey];
            }
          });
          return filteredItem;
        }).filter(item => Object.keys(item).length > 1);
    
        setvariablesSet(newFilteredData);
    
      }, [searchTerm, variableList]);
    
    useEffect(()=>{console.log('destinationFormatKM', destinationFormat)},[destinationFormat])


    //get format types

    const formatApi = async() => {

        try {

            setLoaderSelect(true)

            const res = await axiosInstance.get(`/mappings?formats=1`)

            let data = res.data.result;
            let error = res.data.error;

            console.log('data formatApi', data)

            setFilterImport(data.result);
            setDestinationFormat(destinationFormat)
            //destinationFormatGet()
            getVariableAPI()
            setLoaderSelect(false)

        } catch (err) {
            console.error('fetchData:', err);
        } 

    }

    //get Destination Format api call

      const destinationFormatGet = async () => {

        try{

            const res = await axiosInstance.get(`/settings?destinationFormat=1`)
            
                let data = res.data.result;
                let error = res.data.error;
                //console.log('res', res)
                console.log('data destinationFormatGet', data)
                //console.log('err', error.text, error.code)
                setFilter(data.result.value)

        } catch (err) {
            console.error('fetchData:', err);
        }
        
    }

    //set known mapping on session storage

    useEffect(()=>{
        console.log('knownMap',knownMap);
        sessionStorage.setItem('knownMap',knownMap);
    },[knownMap]);

    //destination format change

    useEffect(()=>{
        console.log('filter',filter)

        if((filter !== '0' && filter !== '') || filterFileType !== ''){

            getVariableAPI();

        }

    },[filterFileType, filter]);

    //Mapping format dropdown

    const filterFormatApply = (e) =>{
        console.log('filter', e.target.value)
        setFilter(e.target.value);
        setFilterFileType(filterTypeImport.at(0).value)

        if(e.target.value === '0'){
            setOpenMapUpload(true)
        }
    }

    useEffect(()=>{console.log('variables',variables)},[variables])

    //set variables by click

    const handleMappings = (event, newVarible) => {
        console.log(variables.includes(newVarible), variables, newVarible)
            if((variables.length > newVarible.length) || lastCursorPosition){
                setVariables([...newVarible]);
            }
    };

    //set variables by default

    useEffect(()=>{

        console.log('variblesData',variableList)
        const defValues = [];
        for (const item of variableList) {
            for (const [subKey, subItem] of Object.entries(item)) {
                console.log('Default',item.Default)
                if (subItem.Default) {
                    defValues.push(subItem.value);
                }
            }
        }
        setVariables(defValues);

    },[variablesSet])

    //add varible

    const handleItemClick = (item) => {
        if (lastCursorPosition && item && !variables.includes(item)) {

            //set the selected value to valible array

            console.log('variablesSet',variablesSet)

            variablesSet.forEach(varItem => {
                Object.keys(varItem).forEach(subKey => {
                  if (subKey !== 'key' && varItem[subKey].value === item) {
                    varItem[subKey].Default = true;
                  }
                });
            });

            //add the selected varible to xml

            const textNode = document.createTextNode(`${item}`);  // Create a text node with curly braces and item value
            lastCursorPosition.insertNode(textNode); 
            setLastCursorPosition(null) // Insert the text node at the last cursor position

            /*if(document.getElementById('cursorIndicator')){
                document.getElementById('cursorIndicator').remove();
            }

            Update the content state if needed
            setContent(contentEditableNode.innerHTML);

            const updatedXmlData  = [...filteredData]
            updatedXmlData.at(clickId).line = document.getElementById(`input-${clickId}`).textContent;
            console.log('Updated XML data:', updatedXmlData);*/

            let updatedXmlData  = document.getElementById(`input-xml`).textContent;
            setFilteredData(updatedXmlData);

        }
        else if(item && variables.includes(item)){

            //set the selected value to variable array

            console.log('item >>>>>', item, document.getElementById(`input-xml`).textContent)

            /*variablesSet.forEach(varItem => {
                Object.keys(varItem).forEach(subKey => {
                  if (subKey !== 'key' && varItem[subKey].value === item) {
                    varItem[subKey].Default = false;
                  }
                });
            });

            const removeVar = filteredData.map(obj => {
                return {
                  ...obj,
                  line: obj.line.replace(`${item}`, '')
                };
            });
            setFilteredData(removeVar)*/

            let removedXmlData  =  document.getElementById(`input-xml`).textContent.replace(item, '');
            setFilteredData(removedXmlData);

        }

        if(lastCursorPosition === null && !variables.includes(item)){
            setOpenWarning(true)
            setTextWarning("Select the location on the right side where you'd like to add variables.")
        }
    };

    /*useEffect(()=>{
        console.log('filteredData',filteredData)
        const xmlString = filteredData.map((item) => {
            return item.line
        })

        setXmlString(xmlString.join(''))


    },[filteredData])*/


     useEffect(()=>{
        console.log('filteredData',filteredData)
    },[filteredData])

    //xml save

    const submit = async() => {

        let getXMLText = document.getElementById('input-xml').textContent;
        setFilteredData(getXMLText);

        try {

            const data = JSON.stringify({
                "categoryId": filter,
                'documentType': filterFileType, 
                "structure": getXMLText
            })

            const res = await axiosInstance.post(`/update_mappings`, data)

            let result = res.data.result;
            let error = res.data.error;

            console.log('data submit', result)
            setOpenSuccess(true)
            setTextSuccess(result.message)
            getVariableAPI();
                   
        } catch (err) {
            console.error('fetchData:', err);
        } 

    }

    const cancel = () => {
        console.log('cancel')
    }

    useEffect(()=>{
        console.log('filterImport >>',filterImport)
    },[filterImport])

    useEffect(()=>{
        console.log('filterTypeImport >>',filterTypeImport, filterTypeImport.length)
    },[filterTypeImport])

    useEffect(()=>{
        console.log('filterTypeImport >> epltyuigrh',filterTypeImport, filterTypeImport.length)
    },[])

    useEffect(()=>{
        if(filterImport.length > 0){
            let selMap = filterImport.filter( item => item.value == filter)
            if(selMap.length > 0){
                setFilterTypeImport(selMap.at(0).formats)
                console.log('filter selMap', selMap, selMap.at(0).formats)
                setFilterFileType(selMap.at(0).formats.at(0).value)
            }
            else{
                console.log('filter selMap else', selMap)
            }
        }
    },[filter])

    useEffect(()=>{
        destinationDefSet();
    },[filterImport])

    //set default destination format

    const destinationDefSet = () => {
        if(filterImport.length > 0){
            let selDef = filterImport.filter( item => item.default == true)
            if(selDef.length > 0){
                setFilter(selDef.at(0).value)
                console.log('filter selDef', selDef, selDef.at(0).value)
                
            }
            else{
                console.log('filter selDef else', selDef)
            }
        }
    }


    return(
        <ThemeProvider theme={ThemeCustom}>
            <MappingUploadModal 
                openMapUpload={openMapUpload} 
                setOpenMapUpload={setOpenMapUpload} 
                setFilter={setFilter}
                formatApi={formatApi}
                filter={filter}
                setOpenSuccess={setOpenSuccess}
                setTextSuccess={setTextSuccess}
                setOpenFail={setOpenFail}
                setTextFail={setTextFail}
                destinationFormatGet={destinationDefSet}
                tokenReview={tokenReview}
            />
            <Grid container className='m-0 p-0'>
                <Grid xs={12} sm={5} className='p-0 pr-4 border-r border-solid border-0 border-cultured'>
                    <div className='mb-4 flex justify-between'>
                        <FormControl margin="normal" className='m-0 w-[49%]'>
                        { loaderSelect ? (
                            <SklInput/>
                        ):(
                            <Select
                                className='select type-filter-select'
                                value={filter}
                                onChange={filterFormatApply}
                                displayEmpty
                                inputProps={{'aria-label': 'Document fromatting type'}}
                            >
                                { filterImport.length > 0 && filterImport.map((filter, index) => (
                                    <MenuItem key={index} value={filter.value} className='type-filter-item-with-add'>{filter.name}</MenuItem>
                                ))}
                                <MenuItem value={'0'} className='menu-item-center'>+ Add New</MenuItem>
                            </Select>
                        )}    
                        </FormControl>
                        <FormControl margin="normal" className='m-0 w-[49%]'>
                        {loaderSelect? (
                            <SklInput/>
                        ):(
                            <Select
                                className='select type-filter-select'
                                value={filterFileType}
                                onChange={(e)=>setFilterFileType(e.target.value)}
                                displayEmpty
                                inputProps={{'aria-label': 'Document file type'}}
                            >
                                { filterTypeImport.length > 0 && filterTypeImport.map((filter, index) => (
                                    <MenuItem key={index} value={filter.value} className='type-filter-item review-doc'><div className='type-filter-item-filter filter-hide'>File Type:</div>{filter.name}</MenuItem>
                                ))}
                            </Select>
                        )}    
                        </FormControl>
                    </div>
                    <div className='pt-4 md:border md:border-solid md:border-cultured rounded-xl'>
                        <div className=''>
                            <FormControl variant="standard"  margin="normal" className='w-full p-4 py-0 m-0'>
                            {loaderView?(
                                 <SklInput/>
                            ):(
                                <OutlinedInput className='search-input known-map-search rounded-xl'
                                    autoComplete="off" 
                                    placeholder="Search variables" 
                                    type='text'
                                    value={searchTerm}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <img src={SearchIcon} alt='icon' className='w-5'/>
                                        </InputAdornment>
                                    }
                                    onChange={(e) => {setSearchTerm(e.target.value)}}
                                />
                            )}
                            </FormControl>
                            <SimpleBar className={`${true?'min-h-knownMap-edit':'min-h-knownMap'} ${loaderView? 'overflow-hidden':''}`}>
                            {loaderView?(
                                <SklVariableList/>
                            ):(
                                <VariableList
                                    variablesSet={variablesSet}
                                    variables={variables}
                                    handleMappings={handleMappings}
                                    handleItemClick={handleItemClick}
                                />
                            )}
                            </SimpleBar>
                        </div>
                        
                    </div>
                </Grid>
                <Grid xs={12} sm={7} className='p-0 pl-4'>
                    <div className='scroll-light'>
                    <SimpleBar className={`${true?'min-h-knownMap-xmlView-edit':'min-h-knownMap-xmlView'} rounded-xl ${loaderView? 'bg-white md:border md:border-solid md:border-cultured overflow-hidden' : 'bg-primary'}`}>
                            {loaderView?(
                                <SklXmlViewer/>
                            ):(
                                <XmlEditViewer 
                                    nestedMap={filteredData} 
                                    setLastCursorPosition={setLastCursorPosition}
                                    setClickId={setClickId}
                                    clickId={clickId}
                                    indent={false}
                                    setFilteredData={setFilteredData}
                                    lastCursorPosition={lastCursorPosition}
                                />
                            )}
                        </SimpleBar>
                    </div>
                </Grid>
                <SettingsFooter submit={submit} cancel={''}/>
            </Grid>  
        </ThemeProvider>
    )
}

export default KnowMap;
