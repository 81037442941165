import React, {useState, useEffect} from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import SimpleBar from 'simplebar-react';
import { useOutletContext } from 'react-router-dom';
import { useBaseUrlContext } from '../../context/Base_url';
import { useAuthContext } from '../../context/Auth';
import useAxiosInstance from '../../api/interceptor';

import {ThemeProvider, Typography, Select , FormControl, MenuItem} from '@mui/material';

import { ThemeCustom} from "../../styles/Theme";
import 'simplebar-react/dist/simplebar.min.css';
import './settings_styles.scss';

import MappingUploadModal from '../modal/Modal_mapping_upload';
import SettingsFooter from '../nav/Settings_footer';
import ApisTable from './APIs_table';
import ButtonImg from '../button/Btn_img';
import SecretKeyModal from '../modal/Modal_secret_key';
import DeleteV2Modal from '../modal/Modal_delete_V2';
import SklInput from '../skeletons/Skl_input';
import APICredModal from '../modal/Modal_api_credentials';

import KeyIcon from '../../assets/key.svg';
import ShieldIcon from '../../assets/shield.svg';


const ApiArray = [{id:'1', name:'Secret key', key:'d1f8a3b4-9e8c-42d9-a1e5-7e5c2b8d6f3a', date: 'August 24, 2023'},{id:'2', name:'Secret key', key:'d1f8a3b4-9e8c-42d9-a1e5-7e5c2b8d6f3a', date: 'August 22, 2023'}, {id:'3', name:'Secret key', key:'sk-...wXPU', date: 'August 24, 2023'},{id:'4', name:'Secret key', key:'sr-...wXPz', date: 'August 22, 2023'}, {id:'5', name:'Secret key', key:'sk-...wXPU', date: 'August 24, 2023'},{id:'6', name:'Secret key', key:'sr-...wXPz', date: 'August 22, 2023'}]


const FormatAPI = (props) => {

    const [destinationFormat, setDestinationFormat] = useState('ubl_2.3');
    const [filter, setFilter] = useState('');
    const [filterImport, setFilterImport] = useState([]);
    const [openMapUpload, setOpenMapUpload] = useState(false);
    const [openSecretKey, setOpenSecretKey] = useState(false);
    const [editId, setEditId] = useState(0);
    const {setOpenFail} = useOutletContext(); 
    const {setTextFail} = useOutletContext(); 
    const {setOpenSuccess} = useOutletContext(); 
    const {setTextSuccess} = useOutletContext(); 
    const {devMode} = useOutletContext(); 
    const [openDelete, setOpenDelete] = useState(false);
    const [delText, setDelText] = useState('API')
    const [apiLoading, setAPILoading] = useState(true)
    const {BaseUrl} = useBaseUrlContext()
    const {tokenReview, popupAction} = useAuthContext()
    const axiosInstance = useAxiosInstance();
    const [btnLoading, setBtnLoading] = useState(false);
    const [openApiCredent, setOpenApiCredent] = useState(false);
    const [apiCredData, setApiCredData] = useState([]);
    const [clientId, setClientId] = useState('d1f8a3b4-9e8c-42d9-a1e5-7e5c2b8d6f3a')
    const [clientSecret, setClientSecret] = useState('4yJ8eT1oQvZ3xC9wF7uM5sXaA1pL6vD2')
    const [keyName, setKeyName] = useState('Secret key 2024')
    const [loader, setLoader] = useState(false);

    //data retriving on load

    useEffect(()=>{
        formatApi();
        getApiCredData();
        //setFilter(props.systemDestinationFormat)
    },[])

    
    //get format types

    const formatApi =  async() => {

        setAPILoading(true)


        try {

            const res = await axiosInstance.get(`/mappings?formats=1&validOnly=1`)

            let data = res.data.result;
            let error = res.data.error;

            console.log('data', data)
            setFilterImport(data.result);
            setAPILoading(false)
                       
        } catch (err) {
            console.error('fetchData:', err);
        } 

    }

    //format dropdown

    const filterFormatApply = (e) =>{
        console.log('filter', e.target.value)
        setFilter(e.target.value);
        destinationFormatSet(e.target.value);

        if(e.target.value === 0){
            setOpenMapUpload(true)
        }
    }

    //submit

    // const submit = () => {
    //     console.log('submit')
    //     destinationFormatSet();
    // }

    //set Destination Format api call

    const destinationFormatSet = async(filterVal) => {

        try {

            const res = await axiosInstance.post(`/settings`,{
                    destinationFormat: filterVal
                })
               
            let data = res.data.result;
            let error = res.data.error;

            console.log('data', data)
            
            setOpenSuccess(true)
            setTextSuccess(data.message)
            //destinationFormatGet();

        } catch (err) {
            console.error('fetchData:', err);
        } 
            
    }

    //get Destination Format api call


    const getApiCredData = async () => {

        try {

            setLoader(true)

            const res = await axiosInstance.get(`/apiCredentials`)

            let data = res.data.result;
            let error = res.data.error;

            console.log('data', data)
            setApiCredData(data.data)
            setLoader(false)

        } catch (err){
            setLoader(false)
            console.error('The server encounted an error and could not complete your request.')
        }
        
    }

    //cancel

    const cancel = () => {
        console.log('cancel')
        
    }

    useEffect(()=>{
        if(filterImport.length > 0){
            let selDef = filterImport.filter( item => item.default == true)
            if(selDef.length > 0){
                setFilter(selDef.at(0).value)
                console.log('filter selDef', selDef, selDef.at(0).value)
                
            }
            else{
                console.log('filter selDef else', selDef)
            }
        }
    },[filterImport])

    //API delete

    const deleteFun = async () => {
        console.log('deleteFun')

        try {

            const res = await axiosInstance.delete(`/apiCredentials?client_id=${editId}`)

            let data = res.data.result;
            let error = res.data.error;

            setOpenDelete(false);
            setOpenSuccess(true)
            setTextSuccess(data.message)
            getApiCredData();

        } catch (err) {
            console.error('fetchData:', err);
        } 

    }

    return(
        <ThemeProvider theme={ThemeCustom}>
            <MappingUploadModal 
                openMapUpload={openMapUpload} 
                setOpenMapUpload={setOpenMapUpload} 
                setFilter={setFilter}
                formatApi={formatApi}
                tokenReview={props.tokenReview}
            />
            <SecretKeyModal 
                openSecretKey={openSecretKey} 
                setOpenSecretKey={setOpenSecretKey}
                setOpenApiCredent={setOpenApiCredent}
                setOpenFail={setOpenFail}
                setTextFail={setTextFail}
                setOpenSuccess={setOpenSuccess}
                setTextSuccess={setTextSuccess}
                setClientId={setClientId}
                setClientSecret={setClientSecret}
                setKeyName={setKeyName}
            />
            <APICredModal
                openApiCredent={openApiCredent} 
                setOpenApiCredent={setOpenApiCredent}
                setOpenFail={setOpenFail}
                setTextFail={setTextFail}
                setOpenSuccess={setOpenSuccess}
                setTextSuccess={setTextSuccess}
                clientId={clientId}
                clientSecret={clientSecret}
                keyName={keyName}
                getApiCredData={getApiCredData}
            />
            <DeleteV2Modal
                openDelete={openDelete}
                setOpenDelete={setOpenDelete}
                deleteFun={deleteFun}
                delText={delText}
                editId={editId}
                tokenReview={props.tokenReview}
                btnLoading={btnLoading}
            />
            <SimpleBar className='min-h-setting-report'>
                <div className='flex justify-between items-start'> 
                    <div className='max-w-[calc(100%-17.125rem)] mr-5'>
                        <Typography variant="text32" className="mb-3" component='div'>
                            Destination Format & APIs
                        </Typography>
                        <Typography variant="text20" className="mb-3" component='div'>
                            Configure output formats and access your secure API keys.
                        </Typography>
                    </div>
                    <div>
                        <ButtonImg btnName="Generate API Credentials" img={ShieldIcon} type="button" classes=" min-w-[11.5rem] px-3" 
                        onClick={() => {setOpenSecretKey(true); setEditId(0)}}/>
                    </div>
                </div>
                <div className='mt-12'>
                    <Typography variant="textBase" className="mb-3 font-semibold" component='div'>
                        Destination Format
                    </Typography> 
                    <FormControl margin="normal" className='m-0 md:min-w-[25rem] md:max-w-[25rem]'>
                        {apiLoading ? (
                            <SklInput/>
                        ):(
                            <Select
                                className='select type-filter-select max-w-[28rem]'
                                value={filter}
                                onChange={filterFormatApply}
                                displayEmpty
                                inputProps={{'aria-label': 'Document fromatting type'}}
                            >
                                { filterImport.length > 0 && filterImport.map((filter, index) => (
                                    <MenuItem key={index} value={filter.value} className='type-filter-item'>{filter.name}</MenuItem>
                                ))}
                            </Select>
                        )}
                    </FormControl>
                </div>
                <div className='my-12'>
                    <Typography variant="text24" className="mb-3" component='div'>
                        API Credentials
                    </Typography>
                    <Typography variant="text20" className="mb-12" component='div'>
                        For security reasons, your secret API keys are only displayed once upon generation. Keep your keys confidential and never expose them in client-side code.
                    </Typography>
                </div>
                <div className='pb-10'>
                    <ApisTable 
                        devMode={devMode} 
                        apiArray={apiCredData} 
                        setOpenDelete={setOpenDelete}
                        setEditId ={setEditId }
                        loader={loader}
                    />
                </div>
            </SimpleBar>
            {/* <SettingsFooter submit={submit} cancel={''} loader={apiLoading}/> */}
        </ThemeProvider>
    )
}

export default FormatAPI;