import React, {useState, useEffect} from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import { useBaseUrlContext } from '../../context/Base_url';
import { useOutletContext } from 'react-router-dom';
import { useAuthContext } from '../../context/Auth';
import useAxiosInstance from '../../api/interceptor';

import {ThemeProvider, Typography} from '@mui/material';

import { ThemeCustom} from "../../styles/Theme";
import 'simplebar-react/dist/simplebar.min.css';
import './settings_styles.scss';

import UsersTable from './Users_table';
import ButtonImg from '../button/Btn_img';
import UserAddModal from '../modal/Modal_add_user';
import DeleteV2Modal from '../modal/Modal_delete_V2';

import AddUserIcon from '../../assets/profile-add.svg'


//const BaseUrl = 'https://docupath.app';

const Users = ({openFail, textFail, openSuccess, textSuccess }) =>{

    const {setOpenFail} = useOutletContext(); 
    const {setTextFail} = useOutletContext(); 
    const {setOpenSuccess} = useOutletContext(); 
    const {setTextSuccess} = useOutletContext(); 
    const {BaseUrl} = useBaseUrlContext()

    const [openAddUser, setOpenAddUser] = useState(false);
    const [editId, setEditId] = useState(1)
    const [users, setUsers] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);
    const [delText, setDelText] = useState('user')
    const [loader, setLoader] = useState(true);
    const [popupLoader, setPopupLoader] = useState(false)
    const {tokenReview, popupAction} = useAuthContext()
    const axiosInstance = useAxiosInstance();
    const [btnLoading, setBtnLoading] = useState(false);

    useEffect(() => {

        getTableData();

    },[])   

    //get user table data

    const getTableData = async() =>{

        try {

            setLoader(true)

            const res = await axiosInstance.get(`/users`)

            let data = res.data.result;
            let error = res.data.error;

            console.log(data.users)
            setUsers(data.users)
            setLoader(false)
       

        } catch (err) {
            console.error('fetchData:', err);
        }     

    }

    const deleteFun = async() => {

        console.log('delete function region-------------------')
        setBtnLoading(true)

        try {

            const deleteId = JSON.stringify({
                "userId": editId
            });

            const res = await axiosInstance.delete(`/users`,{data : deleteId})

            let data = res.data.result;
            let error = res.data.error;

            setOpenDelete(false);
            getTableData();
            setOpenSuccess(true)
            setTextSuccess(data.message)     
            setBtnLoading(false)

        } catch (err) {
            console.error('fetchData:', err);
            setBtnLoading(false)
        } 
        
    }

    useEffect(()=>{console.log('tokenReview region',tokenReview)},[tokenReview])
    
    return(
        <ThemeProvider theme={ThemeCustom}>
            <DeleteV2Modal
                openDelete={openDelete}
                setOpenDelete={setOpenDelete}
                deleteFun={deleteFun}
                delText={delText}
                editId={editId}
                tokenReview={tokenReview}
                btnLoading={btnLoading}
            />
            <UserAddModal
                openAddUser={openAddUser} 
                setOpenAddUser={setOpenAddUser} 
                editId={editId}
                getTableData={getTableData}
                setOpenFail={setOpenFail}
                setTextFail={setTextFail}
                setOpenSuccess={setOpenSuccess}
                setTextSuccess={setTextSuccess}
                tokenReview={tokenReview}
            />
            <div className='mb-12'>
                <div className='flex justify-between items-start'> 
                    <div>
                        <Typography variant="text32" className="mb-3" component='div'>
                            User Management
                        </Typography>
                        <Typography variant="text20" className="mb-3" component='div'>
                            Manage user roles, permissions, and access within your organization.
                        </Typography>
                    </div>
                    <div>
                        <ButtonImg btnName="Add Users" img={AddUserIcon} type="button" classes=" min-w-[11.5rem] px-3" 
                        onClick={() => {setOpenAddUser(true); setEditId(0)}}/>
                    </div>
                </div>
            </div>
            <div>
                <UsersTable
                    setOpenAddUser={setOpenAddUser} 
                    setEditId={setEditId}
                    users={users}
                    setOpenFail={setOpenFail}
                    setTextFail={setTextFail}
                    setOpenSuccess={setOpenSuccess}
                    setTextSuccess={setTextSuccess}
                    setOpenDelete={setOpenDelete}
                    loader={loader}
                />
            </div>
         
        </ThemeProvider>
    )
}

export default Users;