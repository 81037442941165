import React, {useState, useEffect} from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import { useOutletContext } from 'react-router-dom';
import { useBaseUrlContext } from '../../context/Base_url';
import { useAuthContext } from '../../context/Auth';
import useAxiosInstance from '../../api/interceptor';
import { useUserContext } from '../../context/User_Access';
import SimpleBar from 'simplebar-react';

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";

import {ThemeProvider, Typography, FormControl, Select, MenuItem, InputAdornment, OutlinedInput} from '@mui/material';

import { ThemeCustom} from "../../styles/Theme";
import './settings_styles.scss';
import 'simplebar-react/dist/simplebar.min.css';

import OrganizationList from './Organization_list';
import ButtonImg from '../button/Btn_img';
import OrgSettingsModal from '../modal/Modal_org_settings';
import DeleteV2Modal from '../modal/Modal_delete_V2';
import OrgSettingsMainModal from '../modal/Modal_org_main_settings';
import DragDropImg from '../file-upload/Drag-image_input';
import 'simplebar-react/dist/simplebar.min.css';
import SklInput from '../skeletons/Skl_input';
import ButtonSecondary from '../button/Btn_secondary';
import DataRetainModal from '../modal/Modal_data_retain_confirm';

import plusIcon from '../../assets/add-light.svg'
import logoIcon from '../../assets/Docupath-Logo.svg'
import TrashIcon from '../../assets/delete.svg'
import UploadIcon from '../../assets/upload.svg';

// import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

// Register the plugins
//registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const dataRetain = [{id:'1', label: '30 days', value:30}, {id:'2', label: '60 days', value: 60}, {id:'3', label: '90 days', value: 90}]

const OrganizationSettings = ({openFail, textFail, openSuccess, textSuccess}) =>{

    const {setOpenFail} = useOutletContext(); 
    const {setTextFail} = useOutletContext(); 
    const {setOpenSuccess} = useOutletContext(); 
    const {setTextSuccess} = useOutletContext(); 
    const {BaseUrl} = useBaseUrlContext();

    const [openOrgSetting, setOpenOrgSetting] = useState(false);
    const [openOrgMainSetting, setOpenOrgMainSetting] = useState(false);
    const [editId, setEditId] = useState(0)
    const [parentOrgId, setParentOrgId] = useState(0)
    const [organization, setOrganization] = useState([]);
    const [orgEditData, setOrgEditData] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);
    const [delText, setDelText] = useState('sub organization')
    const [isSubOrg, setIsSubOrg] = useState(1)
    const [inboxDomain, setInboxDomain] = useState('')
    const [isLoader, setIsLoader] = useState(true)
    const [popupLoader, setPopupLoader] = useState(false)
    const {tokenReview, popupAction} = useAuthContext()
    const axiosInstance = useAxiosInstance();
    const [btnLoading, setBtnLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [allowFilesDrag, setAllowFilesDrag] = useState(false);
    const [mouseHover, setMouseHover] = useState(false);
    //const [customLogo, setCustomLogo] = useState(null)
    const [preview, setPreview] = useState(null);
    const {setUserDetails, orgLogoCustom} = useUserContext()
    const [dataRetainValue, setDataRetainValue] = useState(0);
    const [apiLoading, setApiLoading] = useState(true)
    const [retainValidate, setRetainValidate] = useState(false)
    const [openDataRetain, setOpenDataRetain] = useState(false)

    //remove later
    useEffect(()=>{console.log('editId ', editId)},[editId])

    const deleteFun = async() => {

        console.log('delete function org-------------------')
        setBtnLoading(true)

        try {

            const deleteId = JSON.stringify({
                "orgId": editId
            });

            const res = await axiosInstance.delete(`/organization`,{data : deleteId})

            let data = res.data.result;
            let error = res.data.error;

            setOpenDelete(false);
            setOpenSuccess(true)
            setTextSuccess(data.message)
            getOrgData();
            setBtnLoading(false)

        } catch (err) {
            console.error('fetchData:', err);
            setBtnLoading(false)
            if (!err?.response?.alertShown) {
                setOpenFail(true)
                err?.response?.data?.error.text ? setTextFail(err.response.data.error.text): setTextFail('The server encounted an error and could not complete your request.')
            }
        }    

    }

    //data retaintion submit

    const sendDataRetain = async () => {

        console.log('dataRetainValue', dataRetainValue)

        try {

            const dataRetain = {
                "dataRetentionPeriod": dataRetainValue
            }

            const res = await axiosInstance.put(`/settings`, dataRetain)

            let data = res.data.result;
            let error = res.data.error;

            setOpenSuccess(true)
            setTextSuccess(data.message)
            setOpenDataRetain(false)

        } catch (err) {
            console.error('fetchData:', err);
            setOpenDataRetain(false)
            if (!err?.response?.alertShown) {
                setOpenFail(true)
                err?.response?.data?.error.text ? setTextFail(err.response.data.error.text): setTextFail('The server encounted an error and could not complete your request.')
            }
        }    

    } 

    //get org data and  domain

    useEffect(() => {

        getOrgData();
        getDataRetention()
        const hostname = window.location.host;
        const subDomain = hostname.split('.').slice(0, -2).join('.')
        const mainDomain = hostname.split('.').slice(-2, -1).join('.')
        const attachEmail = hostname.includes('localhost') ? '-pagerotest@docupathdev.app' : `-${subDomain}@${mainDomain}.app`
        setInboxDomain(attachEmail);

    },[])   

    // get organization data

    const getOrgData = async() =>{

        try {

            setIsLoader(true)

            const res = await axiosInstance.get(`/organization`)

            let data = res.data.result;
            let error = res.data.error;

            console.log('organization', data.organizations)
            setOrganization(data.organizations)
            setIsLoader(false)

        } catch (err) {
            console.error('fetchData:', err);
            if (!err?.response?.alertShown) {
                setOpenFail(true)
                err?.response?.data?.error.text ? setTextFail(err.response.data.error.text): setTextFail('The server encounted an error and could not complete your request.')
            }
        }         

    }

    // get data retain data

    const getDataRetention = async() =>{
        setApiLoading(true)

        try {

            const res = await axiosInstance.get(`/settings?dataRetentionPeriod=true`)

            let data = res.data.result;
            let error = res.data.error;

            console.log('organization', data.dataRetentionPeriod)
            setDataRetainValue(data.dataRetentionPeriod)
            setApiLoading(false)

        } catch (err) {
            console.error('fetchData:', err);
            setApiLoading(false)
            if (!err?.response?.alertShown) {
                setOpenFail(true)
                err?.response?.data?.error.text ? setTextFail(err.response.data.error.text): setTextFail('The server encounted an error and could not complete your request.')
            }
        }     

    }


    //edit org

    const editOrgData = async(id) =>{

        try {

            setPopupLoader(true)

            const res = await axiosInstance.get(`/organization?orgId=${id}`)

            let data = res.data.result;
            let error = res.data.error;

            console.log('organization', data.organization)
            setOrgEditData(data.organization)
            setPopupLoader(false)

        } catch (err) {
            console.error('fetchData:', err);
            if (!err?.response?.alertShown) {
                setOpenFail(true)
                err?.response?.data?.error.text ? setTextFail(err.response.data.error.text): setTextFail('The server encounted an error and could not complete your request.')
            }
        }     

    }

    
    const handleDrop = (e) => {
        //console.log('handleDrop')
        setTimeout(()=>{
            setAllowFilesDrag(false)
        },200)
    }

    const handleDragOver = (e) => {
        //console.log('handleDragOver')
        if(allowFilesDrag === false){
            setAllowFilesDrag(true)
        }
    }

    const handleDragLeave = (e) => {
        //console.log('handleDragLeave')
        if(allowFilesDrag === true){
            setAllowFilesDrag(false)
        }    
    }

    const handleMouseOver = (e) => {
        console.log('handleMouseOver')
        if(orgLogoCustom){
            console.log('handleMouseOver if')
            setMouseHover(true)
        }
    }

    const handleMouseEnter = (e) => {
        console.log('handleMouseEnter')
        if(orgLogoCustom){
            console.log('handleMouseEnter if')
            setMouseHover(true)
        }
    }
    
    const handleMouseLeave = (e) => {
        console.log('handleMouseLeave')
        if(orgLogoCustom){
            console.log('handleMouseLeave if')
            setMouseHover(false)
        }
    }

    useEffect(()=>{
        console.log('files', files)
    },[files])

    useEffect(()=>{console.log('tokenReview region',tokenReview)},[tokenReview])
    useEffect(()=>{console.log('editOrgData***',orgEditData)},[orgEditData])

    //delete uploaded logo 

    const deleteLogo = async() => {

        console.log('delete function org-------------------')

        try {

            const res = await axiosInstance.delete(`/settings?deleteLogo=true`)

            let data = res.data.result;
            let error = res.data.error;

            localStorage.removeItem('customLogo');
            localStorage.removeItem('customLogo');
            setUserDetails(undefined, undefined, undefined, undefined, undefined, null)

            if(error.code === ''){
                setOpenSuccess(true)
                setTextSuccess(data.message)
            }

        } catch (err) {
            console.error('fetchData:', err);
            if (!err?.response?.alertShown) {
                setOpenFail(true)
                err?.response?.data?.error.text ? setTextFail(err.response.data.error.text): setTextFail('The server encounted an error and could not complete your request.')
            }
        }    

    }

    //upload logo

    const uploadLogo = async () => {

        console.log('uploadLogo', files.at(0).file, files)

        const formData = new FormData();
        formData.append('logo', files.at(0).file);

        try{
    
            const res = await axiosInstance.post(`/settings`, formData)
            console.log('res logo', res)
            let data = res.data.result;
            let error = res.data.error;

            //setCustomLogo(data.logo)   
            setUserDetails(undefined, undefined, undefined, undefined, undefined, data.logo) 
            localStorage.setItem('customLogo', data.logo);
            console.log('data.logo', data.logo)
            setFiles([])
            setOpenSuccess(true)
            setTextSuccess(data.message)

        }
        catch(err) {
            console.error('Upload failed:', err);
            if (!err?.response?.alertShown) {
                setOpenFail(true)
                err?.response?.data?.error.text ? setTextFail(err.response.data.error.text): setTextFail('The server encounted an error and could not complete your request.')
            }
        }    
    }

    //file preview

    useEffect(()=>{
        console.log('files', files)
        if (files.length > 0) {
            const file = files[0].file;
            const reader = new FileReader();
            reader.onloadend = () => {
              setPreview(reader.result);
            };
            reader.readAsDataURL(file);
          } else {
            setPreview(null);
          }
    },[files])

    //data retain value validate

    const retainValsanitize = (value) => {
        // Remove any leading zeros
        const sanitizedValue = value.replace(/^0+/, '') || '0';
        const numberValue = Number(sanitizedValue);

        if (!/^\d+$/.test(sanitizedValue) || isNaN(numberValue) || numberValue < 0) {
            setRetainValidate(true);
        } else {
            console.log('Submitted value:', numberValue);
            setDataRetainValue(sanitizedValue);
            setRetainValidate(false);
        }
    };

    const retainValHandling = (value) => {
        // Allow only digits and limit to 4 characters
        if (/^\d{0,4}$/.test(value)) {
            retainValsanitize(value);
            setRetainValidate(false);
        }
        else{
            setRetainValidate(true);
        }
    }

    return(
        <ThemeProvider theme={ThemeCustom}>
            <DataRetainModal
                sendDataRetain={sendDataRetain}
                setOpenDataRetain={setOpenDataRetain}
                openDataRetain={openDataRetain}
            />
            <DeleteV2Modal
                openDelete={openDelete}
                setOpenDelete={setOpenDelete}
                deleteFun={deleteFun}
                delText={delText}
                editId={editId}
                tokenReview={tokenReview}
                btnLoading={btnLoading}
            />
            <OrgSettingsModal
                openOrgSetting={openOrgSetting} 
                setOpenOrgSetting={setOpenOrgSetting} 
                editId={editId}
                setOpenFail={setOpenFail}
                setTextFail={setTextFail}
                setOpenSuccess={setOpenSuccess}
                setTextSuccess={setTextSuccess}
                tokenReview={tokenReview}
                isSubOrg={isSubOrg}
                getOrgData={getOrgData}
                orgEditData={orgEditData}
                setOrgEditData={setOrgEditData}
                setEditId={setEditId}
                popupLoader={popupLoader}
                parentOrgId={parentOrgId}
            />
            <OrgSettingsMainModal
                openOrgMainSetting={openOrgMainSetting} 
                setOpenOrgMainSetting={setOpenOrgMainSetting} 
                editId={editId}
                setOpenFail={setOpenFail}
                setTextFail={setTextFail}
                setOpenSuccess={setOpenSuccess}
                setTextSuccess={setTextSuccess}
                tokenReview={tokenReview}
                inboxDomain={inboxDomain}
                getOrgData={getOrgData}
                orgEditData={orgEditData}
                setOrgEditData={setOrgEditData}
                popupLoader={popupLoader}
            />
            <SimpleBar className='min-h-setting-report pr-4'>
            <div className='mb-12'>
                <div className='flex justify-between items-start'> 
                    <div>
                        <Typography variant="text32" className="mb-3" component='div'>
                            Organization Settings
                        </Typography>
                        <Typography variant="text20" className="mb-3" component='div'>
                            Customize your organization's preferences and configurations
                        </Typography>
                    </div>
                    <div>
                        <ButtonImg btnName="Add New Organization" img={plusIcon} type="button" classes=" min-w-[11.5rem] px-3" 
                        onClick={() => {setOpenOrgMainSetting(true); setEditId(0)}}/>
                    </div>
                </div>
                <div className='max-w-[28rem] mt-12'>
                    <div 
                        className={`border-dashed ${allowFilesDrag && !orgLogoCustom && files?.length < 1 ? 'border-secondary':'border-borderGrayDark' } border-2 rounded-[1.25rem] flex justify-center items-center relative`}
                        onDragOver={(event) => handleDragOver(event)}
                        onMouseOver={(event) => handleMouseOver(event)}
                        onMouseEnter={(event) => handleMouseEnter(event)}
                        onMouseLeave={(event) => handleMouseLeave(event)}
                    >
                        {!orgLogoCustom ? (
                            <div 
                                className={`z-10 w-full `}
                                onDrop={(event) => handleDrop(event)}
                                onDragLeave	={(event) => handleDragLeave(event)}
                            >
                                {
                                    !files?.length > 0 ? (
                                        <DragDropImg
                                            files={files}
                                            setFiles={setFiles}
                                        />
                                    ):(

                                        <div className='relative rounded-[1rem] max-h-[5rem] overflow-hidden m-2'>
                                            <div className='p-6 flex justify-center min-h-[5rem]'>
                                                <img src={preview} alt='logo' className={`w-[14rem] h-[3rem] object-contain ${!preview ? 'hidden' : 'block'}`}/> 
                                            </div>
                                            <div className={`p-4 bg-silver bg-opacity-25 flex justify-center items-center absolute top-0 left-0 w-full min-h-[5rem] opacity-100 rounded-[0.5rem] cursor-pointer`}>
                                                <div className='p-2 rounded-full bg-white mr-3 w-9 h-9 flex justify-center items-center' onClick={() => setFiles([])}>
                                                    <img src={TrashIcon} alt='plusIcon' className='max-w-[1.5rem] w-full'/> 
                                                </div>
                                                <div className='p-2 rounded-full bg-primary w-9 h-9 flex justify-center items-center' onClick={uploadLogo}>
                                                    <img src={UploadIcon} alt='plusIcon' className='max-w-[1.5rem] w-full'/> 
                                                </div>

                                            </div>
                                        </div>    
                                    )
                                }
                            </div>
                        ):(
                            <div className='relative rounded-[1.25rem] w-full max-h-[6rem] overflow-hidden'>
                                <div className='p-6 flex justify-center'>
                                    <img src={orgLogoCustom} alt='logo' className='w-[14rem] h-[3rem] object-contain'/> 
                                </div>
                                <div className={`p-4 m-2 bg-silver bg-opacity-50 flex justify-center absolute top-0 left-0 !w-[calc(100%-1rem)] min-h-[5rem] transition-opacity duration-300 items-center ${mouseHover ? 'opacity-100' : 'opacity-0'} rounded-[0.75rem] cursor-pointer w-full`}>
                                    <div className='p-2 rounded-full bg-white w-9 h-9 flex justify-center items-center' onClick={deleteLogo}>
                                        <img src={TrashIcon} alt='plusIcon' className='max-w-[1.5rem] w-full'/> 
                                    </div>
                                </div>
                            </div> 
                        ) }
                   </div>
                </div>
            </div>
            <div className='mb-12'>
                <Typography variant="textBase" className="mb-3 font-semibold" component='div'>
                    Maximum Data Retention Period
                </Typography> 
                <div className='flex items-center'>
                <FormControl margin="normal" className='m-0 md:min-w-[28rem] md:max-w-[28rem]'>
                    {apiLoading ? (
                        <div className='max-w-[28rem]'>
                            <SklInput/>
                        </div>
                    ):(
                        <>
                        <div className='flex items-center'>
                            <OutlinedInput 
                                id='dataRetain'
                                placeholder='0'
                                defaultValue={0}
                                value={dataRetainValue}
                                onChange={(e) => retainValHandling(e.target.value)}
                                endAdornment={ (<InputAdornment position="end" className='domain-adornment w-1/3 flex justify-center'>Days</InputAdornment>) }
                                autoComplete="off" 
                                type='text'
                                readOnly={false}
                                inputProps={{ maxLength: 4, inputMode: 'numeric' }}
                            />
                        </div>       
                        </>
                    )}
                </FormControl>
                <ButtonSecondary btnName="Change" variant={'primaryBtn'} classes="ml-5 py-[1.125rem]" type="submit" onClick={() => setOpenDataRetain(true)} disabled={false}/>
                </div>
                {retainValidate && <p className='err-msg'>Please enter a valid number.</p>}
            </div>
            <div className='mb-12'>
                <Typography variant="text24" className="mb-3" component='div'>
                    Manage organizations
                </Typography>
                <Typography variant="text20" className="mb-3" component='div'>
                    Create and manage organizations and sub-organizations within your tenant
                </Typography>
            </div>
            <div>
                <OrganizationList
                    setOpenOrgSetting={setOpenOrgSetting} 
                    setEditId={setEditId}
                    organization={organization}
                    setOpenFail={setOpenFail}
                    setTextFail={setTextFail}
                    setOpenSuccess={setOpenSuccess}
                    setTextSuccess={setTextSuccess}
                    setOpenDelete={setOpenDelete}
                    setIsSubOrg={setIsSubOrg}
                    setInboxDomain={setInboxDomain}
                    editOrgData={editOrgData}
                    isLoader={isLoader}
                    setParentOrgId={setParentOrgId}
                    setOpenOrgMainSetting={setOpenOrgMainSetting}
                    setDelText={setDelText}
                />
            </div>
        </SimpleBar> 
        </ThemeProvider>
    )
}

export default OrganizationSettings;