import React, {useState, useEffect, useRef, useCallback} from 'react';
import { useNavigate} from 'react-router-dom';
import { useBaseUrlContext } from '../context/Base_url';
import { useUserContext } from '../context/User_Access';
import { useAuthContext } from '../context/Auth';
import axios from "axios";
import Cookies from 'js-cookie';
import useWebSocket, {ReadyState } from 'react-use-websocket';
import useAxiosInstance from '../api/interceptor';

import {ThemeProvider, Container, Tabs, Tab, Box, AppBar, Typography, Skeleton} from '@mui/material';
import PropTypes from 'prop-types';

import { ThemeCustom} from "./../styles/Theme";
import 'simplebar-react/dist/simplebar.min.css';

import ButtonOutlineImg from '../components/button/Btn_outline_img';
import ButtonSecondary from '../components/button/Btn_secondary';
import SentModal from '../components/modal/Modal_sent';
import UploadModal from '../components/modal/Modal_upload';
import SuccessAlert from '../components/alerts/Success_alert';
import ErrorAlert from '../components/alerts/Fail_alert';
import CompliantAlert from '../components/alerts/Compliant_alert';
import VerifyOutputModal from '../components/modal/Verify_output';
import ApproveConfirmModal from '../components/modal/Modal_approve_confirm';
import InputTab from '../components/approve_tabs/Input_tab';
import OutputTab from '../components/approve_tabs/Output_tab';
import ConCurResponseModal from '../components/modal/Modal_cocurrent_user_response';
import ConCurRequestModal from '../components/modal/Modal_cocurrent_user_request';
import RejectReasonModal from '../components/modal/Modal_reject_reason';
import ApproveSaveModal from '../components/modal/Modal_approve_save';
import SaveChangesModal from '../components/modal/Modal_save_changes';

import ArrowRight from '../assets/right-arrow.png'
import ArrowLeft from '../assets/left-arrow.png'
import sentIcon from '../assets/sent.gif'
import rejectIcon from '../assets/reject.gif'
 
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box sx={{ p: 3 }}>
                <Typography component={'div'}>{children}</Typography>
            </Box>
            )}
         </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const fields = [
    'Date',
    'Name',
    'Quantity',
    'Price',
    'Country',
    'Country code',
    'Discounts',
    'Payment terms'
];

//websocket 

const getListWebSocketUrl = 'wss://b3zk8ul1y4.execute-api.eu-central-1.amazonaws.com/production/';
const getListWebSocketUrlStaging = 'wss://b3zk8ul1y4.execute-api.eu-central-1.amazonaws.com/production/';

const websocketConfig = {
    onOpen: () => console.log('WebSocket connection opened'),
    onClose: () => console.log('WebSocket connection closed'),
}

const Approve = (props) => {

    const [value, setValue] = useState(0);
    const [selectedInvoicesId, setselectedInvoicesId] = useState(JSON.parse(sessionStorage.getItem('selectedDoc'))); // New added for - selectedInvoicesId #####
    const [docId, setDocId] = useState('');
    const [reviewPageIndex, setReviewPageIndex] = useState(JSON.parse(sessionStorage.getItem('reviewPageIndex'))); // New - position #####
    const [jsonInvoiceText, setJsonInvoiceText] = useState([]); //new ###
    const [jsonInvoiceTextOriginal, setJsonInvoiceTextOriginal] = useState([]); //new ###
    const [jsonInvoiceTable, setJsonInvoiceTable] = useState([]); //new ###
    const [jsonInvoiceBarcode, setJsonInvoiceBarcode] = useState([]) //new ###
    const [mapJson, setMapJson] = useState([]);
    const [pdfInvoice, setPdfInvoice] = useState('');
    const [textView, setTextView] = useState('')
    const [invoiceName, setInvoiceName] = useState('');
    const [addField, setAddField] = useState([]);
    const [fieldNameList, setFieldNameList] = useState([]);
    const previousStateRef = useRef([]);
    const [checkStatus, setCheckStatus] = useState('');
    const [readonly, setReadonly ] = useState(false);
    const [orgList, setOrgList] = useState([])
    const [docType, setDocType] =  useState('other');
    const [destinationFormat, setDestinationFormat] = useState('');
    const [disconnectedCount, setDisconnectedCount] = useState(0)
    const [mapTableRow, setMapTableRow] = useState([]);
    const [mapTableData, setMapTableData] = useState([]);
    const [dataFiles, setDataFiles] =  useState([]);
    const [openSent, setOpenSent] = useState(false);
    const [openUpload, setOpenUpload] = useState(false);
    const [sendModalText, setSendModalText] = useState('');
    const [sendModalIcon, setSendModalIcon] = useState(null);
    const [iconScale, setIconScale] = useState(false);
    const [openVerifyOutput, setOpenVerifyOutput] = useState(false);
    const [openApproveConfirm, setOpenApproveConfirm] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openFail, setOpenFail] = useState(false);
    const [openCompliant, setOpenCompliant] = useState(false);
    const [textSuccess, setTextSuccess] = useState('');
    const [textFail, setTextFail] = useState(''); 
    const [docPreviewMode, setDocPreview] = useState(0);
    const [textCompliant, setTextCompliant] = useState('This document does not meet the compliancy requirements in Spain. To make it compliant, please fill in the highlighted fields below.');
    const [changeLogData, setChangeLogData] = useState([])
    const [formModify, setFormModify] = useState(false);
    const [sklCommon, setSklCommon] = useState(true);
    const [sklXmlView, setSklXmlView] = useState(true);
    const [sklFormView, setSklFormView] = useState(true);
    const [sklPdfView, setSklPdfView] = useState(true);
    const [openSaveChanges, setOpenSaveChanges] = useState(false);
    const {BaseUrl} = useBaseUrlContext()
    const {devMode} = useUserContext()
    const {tokenReview, popupAction} = useAuthContext()
    const navigate = useNavigate();
    const inputRefs = useRef({});
    const [textMismatch, setTextMismatch] = useState(false)
    const [tableMismatch, setTableMismatch] = useState(false)
    const [openConcurResponse, setOpenConcurResponse] = useState(false)
    const [openConcurRequest, setOpenConcurRequest] = useState(false)
    const [docViwerMessage, setDocViwerMessage] = useState('')
    const [conCurType, setConCurType] = useState(null)
    const [socketUrl, setSocketUrl] = useState(null);
    const {sendJsonMessage, lastJsonMessage, readyState, getWebSocket} = useWebSocket(socketUrl, websocketConfig);
    const [requestTime, setRequestTime] = useState(0)
    const [documentId, setDocumentId] = useState(null)
    const [requester, setRequester] = useState({})
    const [editRequested, setEditRequested] = useState(false)
    const [editDenyTimmer, setEditDenyTimmer] = useState(null)
    const axiosInstance = useAxiosInstance();
    const [pdfSave, setPdfSave] = useState({})
    const [openRejectReason, setOpenRejectReason] = useState(false)
    const [selOrgid, setSelOrgId] = useState('');
    const [openApproveSave, setOpenApproveSave] = useState(false) 

    useEffect(()=>{
        console.log('selOrgid', selOrgid)
    },[selOrgid, value])

    //************remove later*****************

    //formModify---
 
    // useEffect(()=>{
    //     console.log('jsonInvoiceText > ', jsonInvoiceText, 'orginal >', jsonInvoiceTextOriginal)
    // },[jsonInvoiceText, jsonInvoiceTextOriginal])
 
    // useEffect(()=>{
    //     console.log('jsonInvoiceTable > ', jsonInvoiceTable)
    // },[jsonInvoiceTable])
 
    // useEffect(()=>{
    //     console.log('documentId > ', documentId)
    // },[documentId])

    /***********************************/

    useEffect(()=>{
        console.log('BaseUrl', BaseUrl, BaseUrl.includes('docupathdev'))
    },[])

    const determineWebSocketUrl = useCallback(() => {
        return BaseUrl.includes('docupathdev') ? getListWebSocketUrlStaging : getListWebSocketUrl;
    }, []);

    // to open the WebSocket connection ###

    const openConnection = useCallback(() => {
        console.log('openConnection websoket')

        setSocketUrl(determineWebSocketUrl());

    }, [determineWebSocketUrl]);

    // to close the WebSocket connection ###

    const closeConnection = useCallback(() => {
        console.log('closeConnection websoket')

        const socket = getWebSocket();
        if (socket) {
        socket.close();
        }
        setSocketUrl(null); // Clear the URL to reset the connection

    }, [getWebSocket]);


   //websocket init request ###

   useEffect(() => {
        console.log("*************Connection state changed*************", readyState, getWebSocket())
        if (readyState === ReadyState.OPEN) {
            sendJsonMessage({
                route: "saveConnection",
                accessToken: `${Cookies.get('token')}`
            })
        }
    }, [readyState])

    //concurrent user response to access - web socket ###

    const webSocketResponseAcessAllow = (reqestererId) => {

        console.log("*************Connection state changed*************", readyState, ReadyState.OPEN, documentId, getWebSocket())

        if (readyState === ReadyState.OPEN && documentId) {
            sendJsonMessage({
                route: "requestDocumentLock",
                accessToken: `${Cookies.get('token')}`,
                documentId,
                requestedBy:reqestererId,
                requestApproved:true
            })
            setReadonly(true)
            setConCurType(1)
        }

    }

    //concurrent user response to deny - web socket ###

    const webSocketResponseAcessDeny = (reqestererId) => {

        console.log("*************Connection state changed*************", readyState, ReadyState.OPEN, documentId, getWebSocket())

        if (readyState === ReadyState.OPEN && documentId) {
            sendJsonMessage({
                route: "requestDocumentLock",
                accessToken: `${Cookies.get('token')}`,
                documentId,
                requestedBy:reqestererId,
                requestApproved:false
            })
            setReadonly(false)
            setConCurType(null)
        }

    }

     //concurrent user req access web socket ###

     const webSocketReqest = () => {

        console.log("*************Connection state changed request access*************", readyState, ReadyState.OPEN, documentId, getWebSocket())

        if (readyState === ReadyState.OPEN && documentId) {
            sendJsonMessage({
                route: "requestDocumentLock",
                accessToken: `${Cookies.get('token')}`,
                documentId,
            })
            setEditRequested(true)

        }

    }

    //websocket responses ###

    useEffect(() => {

        if(lastJsonMessage){

            console.log(`*************Got a new message:*************`, lastJsonMessage)
            if(lastJsonMessage.type ? lastJsonMessage.type.includes('request_document') : false){
                setRequester(lastJsonMessage.requestedBy)
                setOpenConcurResponse(true)
                //webSocketResponseAcessAllow(lastJsonMessage.requestedBy.id)
                console.log(`*************Got a new message:************* request_document`)
                //access req
            }
            else if(lastJsonMessage.type ? lastJsonMessage.type.includes('document_unlock'): false){

                if(lastJsonMessage.type.includes('document_unlock_denied')){
                    //access deny
                    setRequester({})
                    setReadonly(true)
                    setConCurType(0)
                }else{
                    //access granted
                    setRequester(lastJsonMessage.unLockedBy)
                    setReadonly(false)
                    setConCurType(null)
                }

                setEditRequested(false)

            }
            else if(lastJsonMessage.type ? lastJsonMessage.type.includes('document_access_changed') && documentId == lastJsonMessage.documentId : false){
                setDocViwerMessage(lastJsonMessage.message);
                setEditRequested(false)
            }
            else if(lastJsonMessage.type && lastJsonMessage.documents ? lastJsonMessage.type.includes('documents_available') && documentId == lastJsonMessage.documents.at(0) : false){
                if(checkStatus === 'no_org' || checkStatus === 'pending_review'){
                    getDocAccess(documentId)
                }
            }
            else if(lastJsonMessage.type && lastJsonMessage.documents ? lastJsonMessage.type.includes('documents_available') && documentId == lastJsonMessage.documents.at(0) : false){
                if(checkStatus === 'no_org' || checkStatus === 'pending_review'){
                    getDocAccess(documentId)
                }
            }
            else if(lastJsonMessage.type ? lastJsonMessage.type.includes('successfully_connected') : false){
                getDocAccess(documentId)
            }
        }

    }, [lastJsonMessage])


    // find the text mismatch ###

    useEffect(() => {

        inputFieldMismatch()

    }, [jsonInvoiceText]);

    //text mismatch function

    const inputFieldMismatch = () => {
        if (jsonInvoiceText) {
            resetAllInputs(jsonInvoiceText);
            const hasMismatch = jsonInvoiceText.some(item => !Object.entries(item).every(([key, value]) => value.value === value.defValue));
            console.log('save results match >', hasMismatch)
            setTextMismatch(hasMismatch)
        }
    }

    // find the table mismatch ###

    useEffect(() => {

        tableDataMismatch()
        //console.log('results match row >', hasMismatch)
    
    }, [jsonInvoiceTable]);

    //table mismatch function

    const tableDataMismatch = () => {

        let hasMismatch = false;

        jsonInvoiceTable.forEach((table) => {
            table.tableRows.forEach((row) => {
                for (const key in row) {
                    if (row[key].value !== undefined && row[key].defValue !== undefined) {
                        //console.log(`Checking key: ${key}, value: ${row[key].value}, valueDef: ${row[key].defValue}`);
                        if (row[key].value !== row[key].defValue) {
                            hasMismatch = true;
                        }
                    }
                }
            });
        });

        setTableMismatch(hasMismatch)

    }

    //save alert enable ###

    useEffect(()=>{

        if(textMismatch || tableMismatch){
            setFormModify(true)
        }
        else{
            setFormModify(false)
        }

        console.log('save alert enable', textMismatch, tableMismatch)

    },[textMismatch, tableMismatch])

    // Function to reset all input fields with values from the updated data object ###

    const resetAllInputs = () => {

        //console.log('resetAllInputs');
    
        Object.keys(inputRefs.current).forEach(key => {
            if (inputRefs.current[key]) {
                const fieldValue = findValueInData(key);
                inputRefs.current[key].value = fieldValue || ''; // set to corresponding value from data object
                //console.log('resetAllInputs if after', inputRefs.current[key]);
            }
        });
    
        //console.log('jsonInvoiceText inside resetAllInputs', jsonInvoiceText, jsonInvoiceTextOriginal);
        setJsonInvoiceTextOriginal(jsonInvoiceText);
    };

    // Function to find the value in the data object corresponding to a given key ###
    
    const findValueInData = (key) => {
        const [itemId, fieldName] = key.split('_');
        for (const item of jsonInvoiceText) {
            if (item.id.toString() === itemId) {
                const value = item[fieldName];
                if (value && value.value !== undefined) {
                    return value.value;
                }
            }
        }
        return '';
    };
    
    // detect dropdown change ###
    
    const handleDropdownChange = () => {
        //console.clear()
        resetAllInputs();
        //console.log('handleDropdownChange')
    };

    //update JsonIvoice according to org change(buyer/seller) ###

    const BuyerDataReplace = (buyerReplaceData) => {

        const buyerReplace =  jsonInvoiceText && jsonInvoiceText.map(item => item.Key !== buyerReplaceData.Key ? item : buyerReplaceData)
        //console.log('buyerReplace', buyerReplace)
        setJsonInvoiceText(buyerReplace)
        setFormModify(true)
        handleDropdownChange()

    }


    //get organization list ###

    useEffect(()=>{

        if(checkStatus == 'no_org'){
            getOrgData()
        }

    },[checkStatus])

    // get organization data API ###

    const getOrgData = async() =>{

        try {

            const res = await axiosInstance.get(`/organization`)

            let data = res.data.result;
            let error = res.data.error;

            //console.log('organization', data.organizations)
            setOrgList(data.organizations)

        } catch (err) {
            //console.error('fetchData:', err);
        }     

    }

    //skeleton handle #####

    useEffect(()=>{

        setTimeout(()=>{
            setSklCommon(false)
        },200)

        //sendSequentialRequests()

        if(reviewPageIndex == 0){
            getInvoiceDataFromSession()
        }

    },[])

    //get the data from session storage to open the first doc

    const getInvoiceDataFromSession = () => {

        const data = sessionStorage.getItem('docProcess') && JSON.parse(sessionStorage.getItem('docProcess'))
        console.log('docProcess', data)

        setJsonInvoiceText(data.documents.at(0).formattedTextJson)
        setJsonInvoiceTextOriginal(data.documents.at(0).formattedTextJson)
        setJsonInvoiceTable(data.documents.at(0).formattedTableJson)
        setJsonInvoiceBarcode(data.documents.at(0).formattedBarcodeJson)
        setCheckStatus(data.documents.at(0).status);
        setInvoiceName(data.documents.at(0).name);
        setTextView(data.documents.at(0).originalDocumentText)
        setDocId(data.documents.at(0).id);
        setDocType(data.documents.at(0).fileType)
        setFieldNameList(fields)
        setDisconnectedCount(data.documents.at(0).disconnectedTagsCount)
        setTimeout(()=>{
            setPdfInvoice(data.documents.at(0).documentLink);
            setSklFormView(false)
        },1000)
        if(data.documents.at(0).fileType === 'xml'){
            setDataFiles(data.documents.at(0).documentText);
        }
        else{
            setDataFiles([]);
        }

    }

    //data retrieving from API #####

    useEffect(()=>{

        if(reviewPageIndex !== undefined && selectedInvoicesId.length > 0){
            const reviewDocId = selectedInvoicesId.at(reviewPageIndex)
            getDocumentData(reviewDocId)
            setDocumentId(reviewDocId);
            console.log('reviewPageIndex inside #####', reviewPageIndex)
        }

        //console.log('reviewPageIndex #####', reviewPageIndex)

    },[reviewPageIndex])

    //open cached pdf

    const openCachedPdf = (docId) => {

        let getFromCache = JSON.parse(sessionStorage.getItem('savedPdf'));
        let pdfLink;
        let pdfName
        if(getFromCache && Object.keys(getFromCache).length > 0){
            pdfLink = getFromCache[docId].documentLink
            pdfName =  getFromCache[docId].documentName
            console.log('pdf xxx', pdfLink)
            setPdfInvoice(pdfLink)
            setInvoiceName(pdfName)
        }

        return pdfLink;

    }

    //data retrive API #####

    const getDocumentData = async (reviewDocId) => {

        //console.log('openConnection', openConnection)

        let cachedUrl = openCachedPdf(reviewDocId)

        setSklPdfView(true)
        setSklFormView(true)
        setCheckStatus('')
        
        console.log('reviewDocId #####', reviewDocId)

        try {
         
            const res = await axiosInstance.get(`/get_documents?ids=${reviewDocId}`)

            let data = res.data.result;
            let error = res.data.error;
          
            //setSelectedInvoice(data.documents)
            setJsonInvoiceText(data.documents.at(0).formattedTextJson)
            setJsonInvoiceTextOriginal(data.documents.at(0).formattedTextJson)
            setJsonInvoiceTable(data.documents.at(0).formattedTableJson)
            console.log('data test', data.documents.at(0).documentLink, cachedUrl)
            if(!cachedUrl){
                setPdfInvoice(data.documents.at(0).documentLink);
                //console.log('data test if')
            }
            setJsonInvoiceBarcode(data.documents.at(0).formattedBarcodeJson)
            setCheckStatus(data.documents.at(0).status);
            setInvoiceName(data.documents.at(0).name);
            setTextView(data.documents.at(0).originalDocumentText)
            setDocId(data.documents.at(0).id);
            setDocType(data.documents.at(0).fileType)
            setFieldNameList(fields)
            setDisconnectedCount(data.documents.at(0).disconnectedTagsCount)
            if(data.documents.at(0).fileType === 'xml'){
                setDataFiles(data.documents.at(0).documentText);
            }
            else{
                setDataFiles([]);
            }
            //console.log('getDocumentData #####',data.documents, data.documents.at(0).formattedTextJson);
            setTimeout(()=>{
                setSklPdfView(false)
                setSklFormView(false)
            },2000)
            if(data.documents.at(0).status === 'no_org' || data.documents.at(0).status === 'pending_review'){
                //getDocAccess(reviewDocId)
                openConnection()
            }
                       
        } catch (err) {
            console.error('fetchData:', err);
        }    
    }

    //check document access - concurrent user

    const getPreFetchDoc = async (docId) => {

        try {

            const res = await axiosInstance.get(`/get_documents?ids=${docId}`)

            let data = res.data.result;
            let error = res.data.error;

            const documentId = data.documents.at(0).id;
            const documentLink = data.documents.at(0).documentLink;
            const documentName = data.documents.at(0).name;

            setPdfSave(prevData => {
                if (!prevData.hasOwnProperty(documentId)) {
                    return { ...prevData, [documentId]: {'documentLink': documentLink, 'documentName': documentName} };
                }
                return prevData;
            });

            //setPdfInvoice(data.documents.at(0).documentLink);
            //sessionStorage.setItem('savedData')

        } catch (err) {
            console.error('fetchData:', err);
        }    

    }

    //send Sequential Requests for pdf queue

    const sendSequentialRequests = async () => {

        let getIdsfromSession = JSON.parse(sessionStorage.getItem('selectedDoc'))

        for (let i = 0; i < getIdsfromSession.length; i++) {
            await getPreFetchDoc(getIdsfromSession[i]);
        }       

    };

    useEffect(()=>{

        //console.log('All requests completed', pdfSave);

        if(Object.keys(pdfSave).length > 0){
            sessionStorage.setItem('savedPdf', JSON.stringify(pdfSave));
        }

    },[pdfSave])

    //check document access - concurrent user

    const getDocAccess = async (reviewDocId) => {

        try {

            let documentId = JSON.stringify({
                "documentId":reviewDocId
            })    

            const res = await axiosInstance.post(`/document?lockUser=1`, documentId)

            let data = res.data.result;
            let error = res.data.error;

            //console.log('concurrent result', data.message)
            setReadonly(false);
            setConCurType(null)
            setOpenConcurRequest(false)

            if(error.code.includes('DOCUMENT_READ_ONLY')){
                //webSocketInit()
                console.log('concurrent - DOCUMENT_READ_ONLY')
                setReadonly(true);
                setConCurType(1)
                setDocViwerMessage(error.text);
                setOpenConcurRequest(true)
            }

        } catch (err) {
            console.error('fetchData:', err);
        }    

    }

    //readonly mode ###

    useEffect(()=>{
        if(checkStatus === 'approved_sent' || checkStatus === 'rejected'){
            setReadonly(true);
        }
        else{
            setReadonly(false);
        }
    },[checkStatus])

    //fail alert ###

    useEffect(()=>{
        setTimeout(()=>{
            setOpenFail(false);
        },5000)
    },[openFail]);

    //success alert ###

    useEffect(()=>{
        //console.log(openSuccess)
        setTimeout(()=>{
            setOpenSuccess(false);
        },10000)
    },[openSuccess]);


    // tab changing ###

    const handleChange = (event, newValue) => {
        console.log('handleChange ', newValue)
        if(newValue === 1 && formModify){
            setOpenSaveChanges(true)
        }
        else if(newValue === 1 && !formModify) {
            formDataPost(true);
            setValue(newValue);
            setSklXmlView(true)
        }
        else{
            setValue(newValue);
            setSklXmlView(true)
            inputFieldMismatch()
            tableDataMismatch()
        }
        
    };

    //add more fields functionalities ??? have to re-structure this ###

    useEffect(()=>{
        /*
        const previousState = previousStateRef.current;
        const added = addField.filter((value) => !previousState.includes(value));
        const removed = previousState.filter((value) => !addField.includes(value));

        previousStateRef.current = addField;
    
        //console.log('findChanges 2',addField, 'added',added, 'remove',removed);

        const addedFile = (keyValuePairs) => {
            if(jsonInvoice.length > 0){
                const addJson = jsonInvoice.map(obj => {

                    //console.log('keyValuePairsxxxx',keyValuePairs)
                    // Find all matching pairs for the current object's Key
                    const matchingPairs = keyValuePairs.filter(pair => pair.key === obj.Key);
                    
                    // Update the object with the values from matching pairs
                    matchingPairs.forEach(pair => {
                    obj[pair.value] = ''; // Inserting the value as a new key in the x object
                    });
                
                    return obj;
                });
                setJsonInvoice(addJson);
                //console.log('setJsonInvoice(addJson);',jsonInvoice )
            }
        }

        const removedFile = (keyValuePairs) => {
            if(jsonInvoice.length > 0){
                const removeJson = jsonInvoice.map(obj => {
                    // Check if any key-value pairs exist for the current object's Key
                    const matchingPairs = keyValuePairs.filter(pair => pair.key === obj.Key);
              
                    // Remove the keys specified in matching pairs from the object
                    matchingPairs.forEach(pair => {
                      delete obj[pair.value];
                    });
              
                    return obj;
                  });
              
                  setJsonInvoice(removeJson);
                  //console.log('setJsonInvoice(removeJson);',jsonInvoice )
            }
        }

        if(added.length > 0 && added !== undefined){
            const [key, value] = added[0].split(':');
            const addKeyValuePairs = [{ key, value }];
            addedFile(addKeyValuePairs)
            //console.log('addKeyValuePairs',addKeyValuePairs);
        }

        if(removed.length > 0 && added !== undefined){
            const [key, value] = removed[0].split(':');
            const remKeyValuePairs = [{ key, value }];
            removedFile(remKeyValuePairs)
            //console.log('remKeyValuePairs',remKeyValuePairs);
        }
        */
    },[addField])

    //file reject ###

    const reject = () =>{

        if(disconnectedCount > 0){
            if(value === 0){
                setOpenVerifyOutput(true);
            }
            else{
                //postReject();
                setOpenRejectReason(true)
            }  
        }
        else{
            //postReject();
            setOpenRejectReason(true)
        }
             
    }

    //file reject API ###

    const postReject = async(reason) => {

        console.log('postReject > ', selOrgid)

        try {

            setReadonly(true)

            let data = {
                data:{
                    formattedTextJson:jsonInvoiceText,
                    formattedTableJson:jsonInvoiceTable
                },
                status:"rejected",
                documentId:docId,
                reason: reason.rejectReason,
                orgId:selOrgid
            }

            const res = await axiosInstance.post(`/set_status`, data)

            let result = res.data.result;
            let error = res.data.error;
            
            setOpenRejectReason(false)
            setOpenUpload(true);
            setSendModalText('Document rejected...');
            setSendModalIcon(rejectIcon)
            setIconScale(false);
            setValue(0)

            setTimeout(()=>{
                setOpenUpload(false);
                removeFromArray();
                setReadonly(false)
            },3000)     

        } catch (err) {
            console.error('fetchData:', err);
            setReadonly(false)
        }     

    }

    //file approve ###

    const approve = () =>{

        if(disconnectedCount > 0){

            if(value === 0){
                setOpenVerifyOutput(true);
            }
            else{
                setOpenApproveConfirm(true);
            }

        }
        else{
            if(formModify){
                setOpenApproveSave(true)
            }
            else{
                postApprove(0)
            }
        }
               
    }

    //post approve files ###

    const postApprove = async(saveData) => {

        console.log('postApprove > ', selOrgid)

        try {

            setReadonly(true)

            let data = {
                data:{
                    formattedTextJson:jsonInvoiceText,
                    formattedTableJson:jsonInvoiceTable
                },
                status:"approved_sent",
                documentId:docId,
                orgId:selOrgid
            }

            const res = await axiosInstance.post(`/set_status?discard=${saveData}`, data)

                let result = res.data.result;
                let error = res.data.error;

                setOpenUpload(true);
                setSendModalText('Sending documents...');
                setSendModalIcon(sentIcon);
                setIconScale(true);
                setValue(0)

                setTimeout(()=>{
                    setOpenUpload(false);
                    removeFromArray();
                    setReadonly(false)
                },3000)
            
        } catch (err) {
            console.error('fetchData:', err);
            setReadonly(false)
        }   
         
    }

    //remove the files from the selected file array after approved or rejected ###   

    const removeFromArray = () =>{
        //console.log('remove array before',selectedInvoice.length, 'posision', position);
        
        let removeDoc = selectedInvoicesId.filter(file => file !== docId);
        //console.log('removeDoc', removeDoc)
        setselectedInvoicesId(removeDoc);
        sessionStorage.setItem('selectedDoc', JSON.stringify(removeDoc))

        //single file list 
        console.clear()
        if(selectedInvoicesId.length === 1){

            if(devMode){
                navigate('/document_review?devMode=true');
            }
            else{
                navigate('/document_review');
            }
            //console.log('single file list')
            
        }
        else{
            //first file 
            if(reviewPageIndex === 0){
                getDocumentData(removeDoc.at(0))
                //console.log('first file')

            }
            //last file
            else if(selectedInvoicesId.length - 1 === reviewPageIndex){
                getDocumentData(removeDoc.at(removeDoc.length - 1))
                //console.log('last file')

            }
            else{
                //console.log('any file')
                getDocumentData(removeDoc.at(reviewPageIndex))
            }
        }

    }

    // data post when destination tab click ###

    const destinationTabClick = () =>{


    }

    //save changes popup

    const savePopup = () => {
        formDataPost();
        setOpenSaveChanges(false)
        setValue(1);
        setSklXmlView(true)
    }

    //discard changes popup

    const discardPopup = () => {
        setOpenSaveChanges(false)
        formDataPost(true);
        setValue(1);
        setSklXmlView(true)
    }

    
    //Form data post ###

    const formDataPost = async(discardVal) => {
        
        console.log('discardVal >', discardVal)

        console.log('formDataPost > ', selOrgid)

        try {

            let data = {
                data:{
                    formattedTextJson:jsonInvoiceText,
                    formattedTableJson:jsonInvoiceTable,
                },
                documentId:docId,
                orgId:selOrgid
            }

            const res = await axiosInstance.post(`/generate_xml?discard=${discardVal? 1:0}`, data)

            let result = res.data.result;
            let error = res.data.error;

            //console.log('response',result.xml)
            DestinationApi()
            getChangeLogData()
            setFormModify(false)
            setTableMismatch(false)
            setTextMismatch(false)
            if(formModify){
                setOpenSuccess(true)
                setTextSuccess(result.message)
                const reviewDocId = selectedInvoicesId.at(reviewPageIndex)
                getDocumentData(reviewDocId)
            }


        } catch (err) {
            console.error('fetchData:', err);
        }   

    }

    // destination nested api call ###

    const DestinationApi = async() => {

        setSklXmlView(true)

        try {

            setMapJson([])
            setDestinationFormat([])
            setMapTableRow([])

            const res = await axiosInstance.get(`/destination_format?documentId=${docId}&string=1`)

            let data = res.data.result;
            let error = res.data.error;

            //console.log('response',data.formattedJson);
            setDestinationFormat(data.destinationFormatTagMappings);
            setMapJson(data.formattedJson)
            setSklXmlView(false);

        } catch (err) {
            console.error('fetchData:', err);
        }   

    }

    //backward click ###

    const invoicePrev = () => {
        //console.log('invoicePrev')
        let decrease = reviewPageIndex - 1;
        setReviewPageIndex(decrease) //new #####
        sessionStorage.setItem('reviewPageIndex', decrease) //new #####
        setDocPreview(0)
        setSelOrgId('')
        setFormModify(false)
        closeConnection()
    }

    //forward click ###

    const invoiceNext = () => {
        //console.log('invoiceNext');
        let increase = reviewPageIndex + 1;
        setReviewPageIndex(increase) //new #####
        sessionStorage.setItem('reviewPageIndex', increase) //new #####
        setDocPreview(0)
        setSelOrgId('')
        setFormModify(false)
        closeConnection()
    }

    //get Change log ###

    useEffect(()=>{

        if(docId){
            getChangeLogData()
            //console.log('getChangeLogData useEffect', docId)
        }

    },[docId])

    //change log api ###

    const getChangeLogData = async() =>{

        //console.log('getChangeLogData**********', docId)

        try {

            const res = await axiosInstance.get(`/changelog?documentId=${docId}`)

            let data = res.data.result;
            let error = res.data.error;

            //console.log('reportsData changetog',data)
            setChangeLogData(data.documents)
                        
        } catch (err) {
            console.error('fetchData:', err);
        }     

    }

    //get buyer data API ###

    const getBuyerData = async(selOrg) =>{

        console.log('getBuyerData > ', selOrgid)
            
            try {
                
                const res = await axiosInstance.get(`/organization?documentId=${docId}&orgId=${selOrg}`)

                //console.log('res', res)
                let data = res.data.result;
                let error = res.data.error;

                BuyerDataReplace(data.formattedJson)
                        
            } catch (err) {
                console.error('fetchData:', err);
            }     
    
    }


    useEffect(()=>{
        console.log('requester id', requester)
    },[requester])

    return(
        <>
            <ThemeProvider theme={ThemeCustom}>
            <div className='absolute top-3 right-8 w-full z-[1999] h-0 CompliantAlert-wrapper'>
                <CompliantAlert 
                    setOpenCompliant={setOpenCompliant} 
                    openCompliant={openCompliant} 
                    alretText={textCompliant}
                />
            </div>
            <div className='absolute top-8 right-8 w-full z-[1999] h-0'>
                <SuccessAlert setOpenSuccess={setOpenSuccess} openSuccess={openSuccess} alretText={textSuccess}/>
                <ErrorAlert setOpenFail={setOpenFail} openFail={openFail} alretText={textFail}/>
            </div>
            <VerifyOutputModal 
                setOpenVerifyOutput={setOpenVerifyOutput} 
                openVerifyOutput={openVerifyOutput} 
                setValue={setValue} 
                formDataPost={formDataPost}
            />
            <SaveChangesModal
                setOpenSaveChanges={setOpenSaveChanges}
                openSaveChanges={openSaveChanges}
                savePopup={savePopup}
                discardPopup={discardPopup}
            />
            <UploadModal 
                modalText={sendModalText} 
                btnEnable={false} 
                iconScale={iconScale} 
                setOpenUpload={setOpenUpload} 
                loaderIcon={sendModalIcon} 
                openUpload={openUpload}
            />
            <SentModal 
                setOpenSent={setOpenSent} 
                openSent={openSent}
                devMode={devMode}
            />
            <ApproveConfirmModal 
                disconnectedCount={disconnectedCount} 
                postApprove={postApprove} 
                setOpenApproveConfirm={setOpenApproveConfirm} 
                openApproveConfirm={openApproveConfirm}  
                formModify={formModify}
                setOpenApproveSave={setOpenApproveSave}
            />
            <ConCurResponseModal
                setOpenConcurResponse={setOpenConcurResponse} 
                openConcurResponse={openConcurResponse}  
                requesterName={requester.name}
                requesterId={requester.id}
                webSocketResponseAcessDeny={webSocketResponseAcessDeny}
                webSocketResponseAcessAllow={webSocketResponseAcessAllow}
            />
            <ConCurRequestModal
                setOpenConcurRequest={setOpenConcurRequest} 
                openConcurRequest={openConcurRequest}  
                docViwerMessage={docViwerMessage}
                webSocketResponseAcessAllow={webSocketResponseAcessAllow}
                requesterName={requester.name}
                requesterId={requester.id}
                setEditDenyTimmer={setEditDenyTimmer}
                setEditRequested={setEditRequested}
            />
            <RejectReasonModal
                openRejectReason={openRejectReason}
                setOpenRejectReason={setOpenRejectReason}
                postReject={postReject}
            />
            <ApproveSaveModal
                setOpenApproveSave={setOpenApproveSave}
                openApproveSave={openApproveSave}
                postApprove={postApprove}
            />
            <div className='mt-[4.5rem] container-up-margin'>
                <Container maxWidth="false">
                    <div>
                        <TabPanel value={value} index={0} className='p-0 remove-pad'>
                            <div>
                                <InputTab
                                    jsonInvoiceText={jsonInvoiceText}
                                    jsonInvoiceTextOriginal={jsonInvoiceTextOriginal}
                                    setJsonInvoiceText={setJsonInvoiceText}
                                    jsonInvoiceTable={jsonInvoiceTable}
                                    setJsonInvoiceTable={setJsonInvoiceTable}
                                    addField={addField}
                                    setAddField={setAddField}
                                    setFormModify={setFormModify}
                                    readonly={readonly}
                                    fieldNameList={fieldNameList}
                                    pdfInvoice={pdfInvoice}
                                    docType={docType}
                                    dataFiles={dataFiles}
                                    invoiceName={invoiceName}
                                    textView={textView}
                                    devMode={devMode}
                                    setDocPreview={setDocPreview}
                                    docPreviewMode={docPreviewMode}
                                    changeLogData={changeLogData}
                                    formModify={formModify}
                                    formDataPost={formDataPost}
                                    setSklFormView={setSklFormView}
                                    sklFormView={sklFormView}
                                    setSklPdfView={setSklPdfView}
                                    sklPdfView={sklPdfView}
                                    jsonInvoiceBarcode={jsonInvoiceBarcode}
                                    orgList={orgList}
                                    checkStatus={checkStatus}
                                    getBuyerData={getBuyerData}
                                    setSelOrgId={setSelOrgId}
                                    inputRefs={inputRefs}
                                    docViwerMessage={docViwerMessage}
                                    conCurType={conCurType}
                                    setConCurType={setConCurType}
                                    webSocketReqest={webSocketReqest}
                                    webSocketResponseAcessAllow={webSocketResponseAcessAllow}
                                    setReadonly={setReadonly}
                                    requesterId={requester.id}
                                    setEditRequested={setEditRequested}
                                    editRequested={editRequested}
                                    setEditDenyTimmer={setEditDenyTimmer}
                                    editDenyTimmer={editDenyTimmer}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1} className='p-0 remove-pad'>
                            <OutputTab 
                                jsonInvoice={mapJson}
                                mapTableRow={mapTableRow}
                                setMapTableRow={setMapTableRow}
                                readonly={readonly}
                                dataFiles={dataFiles}
                                disconnectedCount={disconnectedCount}
                                destinationFormat={destinationFormat}
                                formDataPost={formDataPost}
                                mapTableData={mapTableData}
                                setMapTableData={setMapTableData}
                                devMode={devMode}
                                sklXmlView={sklXmlView}
                                setSklXmlView={setSklXmlView}
                                setSklFormView={setSklFormView}
                                sklFormView={sklFormView}
                            />
                        </TabPanel>
                    </div>    
                </Container>
            </div> 
            <AppBar position="fixed" className='bg-white shadow-none border-t border-0 border-solid border-t-borderGrayDark top-auto bottom-0'>
                <Container maxWidth="false">
                    <div className='py-5 flex justify-between items-center'>
                        <div className='flex items-center'>
                        {sklCommon ? (
                            <>
                                <Skeleton animation="wave" className={`w-[14.375rem] max-h-[3.574rem] h-[3.574rem] rounded-xl`}></Skeleton>
                            </>
                        ):(  
                            <Tabs value={value} className='min-w-[422px]x' onChange={handleChange} aria-label="tabs">
                                <Tab label="Input" disableTouchRipple onClick={()=>{}} {...a11yProps(0)} />
                                <Tab onClick={destinationTabClick} label="Destination" disableTouchRipple {...a11yProps(1)} />
                            </Tabs>
                        )}
                    </div>    
                        {value === 0 && selectedInvoicesId.length > 1 ? (
                        <div className='flex items-center'>
                            {
                                sklCommon ? (
                                    <>
                                        <Skeleton animation="wave" className={`w-[3rem] max-h-[3rem] h-[3rem] rounded-xl`}></Skeleton>
                                        <Skeleton animation="wave" className={` min-w-[2.5rem] max-h-[1.25rem] h-[1.25rem] mx-10 rounded-xl`}></Skeleton>
                                        <Skeleton animation="wave" className={`w-[3rem] max-h-[3rem] h-[3rem] rounded-xl`}></Skeleton>
                                    </>
                                ):(
                                    <>
                                        <ButtonOutlineImg btnName="" variant="primaryOutline" classes={`min-w-[3rem] max-w-[3rem] flex justify-center p-0 imgBtn-mr-0 ${reviewPageIndex+1 < 2 ? 'opacity-25' :'' }`} img={ArrowLeft} type="button" onClick={invoicePrev} disabled={reviewPageIndex+1 < 2 ? true: false}/>
                                        <Typography variant="textBase" className="font-medium px-10" component='div'>{reviewPageIndex+1} of {selectedInvoicesId.length}</Typography>
                                        <ButtonOutlineImg btnName="" variant="primaryOutline" img={ArrowRight} classes={`min-w-[3rem] max-w-[3rem] flex justify-center p-0 imgBtn-mr-0 ${reviewPageIndex+1 === selectedInvoicesId.length ? 'opacity-25' :'' }`} type="submit" onClick={invoiceNext} disabled={reviewPageIndex+1 === selectedInvoicesId.length ? true: false} />
                                    </>
                                )

                            }
                             </div>
                        ):''}
                        <div className='flex'>
                            {sklCommon ? (
                                    <>
                                        <Skeleton animation="wave" className={`w-[7.5rem] max-h-[3.449rem] h-[3.449rem] rounded-xl mr-5`}></Skeleton>
                                        <Skeleton animation="wave" className={`w-[7.5rem] max-h-[3.449rem] h-[3.449rem] rounded-xl`}></Skeleton>
                                    </>
                                ):(
                                    <>
                                        <ButtonSecondary btnName="Reject" variant={'primaryBtn'} classes="mr-5" type="button" onClick={reject} disabled={readonly}/>
                                        <ButtonSecondary btnName="Approve" variant={'secondaryBtn'} type="submit" onClick={approve} disabled={readonly}/>
                                    </>
                            )}        
                        </div>
                    </div>
                </Container>
            </AppBar>
            </ThemeProvider>
        </>
    )
}

export default Approve;