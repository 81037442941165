import React, { createContext, useContext, useEffect, useState} from "react";
import axios from "axios";
import Cookies from 'js-cookie';
import {useNavigate, useLocation} from 'react-router-dom';
import { useBaseUrlContext } from "./Base_url";
import { useUserContext } from "./User_Access";

import UnAuthModal from "../components/modal/Modal_unAuth";
import ErrorAlert from '../components/alerts/Fail_alert';

//create context
const AuthContext = createContext();

//custom hook to use the context 
export const useAuthContext = () => {
    return useContext(AuthContext)
};

//provider component
export const AuthProvider = ({children}) => {

    const {BaseUrl} = useBaseUrlContext()
    const [openUnAuth, setOpenUnAuth] = useState(false);
    const [cookieOptions, setCookieOptions] = useState({})
    const [openFail, setOpenFail] = useState(false);
    const [textFail, setTextFail] = useState('');
    const location = useLocation();

    useEffect(()=>{

        const domain = window.location.hostname
        const baseDomain = domain.split('.').slice(-2).join('.');
        const cookieDomain = domain === 'localhost' ? 'localhost' : `.${baseDomain}`;
        const isSecure = domain === 'localhost' ? false : domain.includes('invoiceai.local') ? false : true;

        setCookieOptions(
            {
                path: '/',
                secure: isSecure, 
                sameSite: 'lax', // Use 'lax' to balance security and accessibility
                //domain: cookieDomain,               
            }
        )
    },[])

    useEffect(()=>{
        console.log('cookieOptions >',cookieOptions)
    },[cookieOptions])


    const tokenReview = async () => {

        let expireTime = Math.trunc(JSON.parse(Cookies.get('expire')))
        let currentTime = Math.trunc(Date.now() / 1000)
    
        if (currentTime < expireTime) {

            //console.log('Token is not expired')
            return true;

        } else {

            console.log('Token has expired, attempting to renew')
            return await tokenRenew();

        }

    }

    const tokenRenew = async () => {
        const refreshToken = Cookies.get('refreshToken');
        console.log('tokenNewed app')
    
        if (!refreshToken) {

            console.error('invalid refresh token')
            console.error('no auth tokenRenew else')
            setOpenUnAuth(true)
            return false;

        }
    
        try {

            const res = await axios.post(`${BaseUrl}/login`, { refreshToken });

            let data = res.data.result;
            let error = res.data.error;
            console.log('res', data.authorization, error.text);

            if (!error.text && !error.code) {

                Cookies.set('token', data.authorization.token, cookieOptions);
                Cookies.set('expire', data.authorization.expiresAt, cookieOptions);
                Cookies.set('refreshToken', data.authorization.refreshToken, cookieOptions);
                console.log('Token renewed successfully.', location.pathname);
                
                return true;

            } else {

                console.log('Token renewal error:', error.text);
                return false;

            }

        } catch (err) {

            console.error('Error during token renewal:', err);
            return false;

        }
    }
    

    const popupAction = (open) => {
        setOpenUnAuth(open)
    }

    const unAuthAccess = () => {

        const refreshToken = Cookies.get('refreshToken');
        const token = Cookies.get('token');

        if (!refreshToken || !token) {
            console.log('token yes',refreshToken)
            window.location.replace('/')
        } else {
            console.log('token no', refreshToken)
        }

    }

    //error alert open

    const errAlertOpen = () => {
        if(!openFail){
            setOpenFail(true);
        }
    }

    //error alert text

    const errAlertText = (errText) => {
        setTextFail(errText);
    }

    //error alert

    useEffect(()=>{

        if(openFail){
            setTimeout(()=>{
                setOpenFail(false);
            },10000)
        }

    },[openFail]);


    return(
        <>
        <UnAuthModal 
            openUnAuth={openUnAuth}
            setOpenUnAuth={setOpenUnAuth}
        />
        <div className='absolute top-8 right-8 w-full z-[1999] h-0'>
            <ErrorAlert setOpenFail={setOpenFail} openFail={openFail} alretText={textFail}/>
        </div>
        <AuthContext.Provider value={{tokenReview, tokenRenew, popupAction, unAuthAccess, errAlertOpen, errAlertText}}>
            {children}
        </AuthContext.Provider>
        </>
    )
}