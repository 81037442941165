import React, {useEffect, useState} from 'react';
import { set, useForm } from "react-hook-form";
import axios from "axios";
import Cookies from 'js-cookie';
import { useBaseUrlContext } from '../../context/Base_url';
import { useAuthContext } from '../../context/Auth';
import useAxiosInstance from '../../api/interceptor';

import {Box, Typography, Modal, FormControl, OutlinedInput, InputLabel, MenuItem, Select, Skeleton} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';
import SklInputPopup from '../skeletons/Skl_input_popup';
import ButtonSecondaryLoader from '../button/Btn_secondary_loader';

import ModalStyle from './Modal_styles.module.scss';
import './Modal_styles.scss';

//const BaseUrl = 'https://docupath.app';


const RulesAddModal = ({openAddRules, setOpenAddRules, editId, getTableData, setOpenFail, setTextFail, setOpenSuccess, setTextSuccess}) =>{

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
    const [uploading, setUploading] = useState(false)
    const [countryList, setCountryList] = useState([])
    const [selCountry, setSelCountry] = useState('')
    const [selCountryCode, setSelCountryCode] = useState('')
    const [popupLoader, setPopupLoader] = useState(false)
    const [editCountry, setEditCountry] = useState('')
    const {BaseUrl} = useBaseUrlContext()
    const {tokenReview, popupAction} = useAuthContext()
    const axiosInstance = useAxiosInstance();
    const [btnLoading, setBtnLoading] = useState(false)

    useEffect(()=>{
        if(openAddRules){
            setUploading(false)
            getcountryList()

            if(editId !== 0 ){
                editRules()
            }
            else{
                setEditCountry('')
            }
        }
    },[openAddRules])

    //get selected countries code

    useEffect(()=>{
        console.log('selectedCountry',selCountry)
        //const countryCode =  selectedCountry.map(item => item.code);
        if(selCountry){
            setSelCountryCode(JSON.parse(selCountry))
        }
    },[selCountry])

    const cancel = () =>{

        setOpenAddRules(false)
        reset({rules:'', country:''})
        setSelCountry('')

    }

    //get country list

    const getcountryList = async() =>{

        try {
          
            const res = await axiosInstance.get(`/country`)

            let data = res.data.result;
            let error = res.data.error;

            setCountryList(data.countries)
        
        } catch (err) {
            console.error('fetchData:', err);
            setOpenFail(true)
            setTextFail('The server encounted an error and could not complete your request.')
        }     

    }

    //get rules data

    const editRules = async() => {

        try {

            setPopupLoader(true)

            const res = await axiosInstance.get(`/instruction_build?countryCode=${editId}`)

            let data = res.data.result;
            let error = res.data.error;

            console.log(res.data.result)
            const resObj = data.country;
            const {instructions, ...selCountry} = resObj;
            console.log('selCountry',selCountry)
            setSelCountry(JSON.stringify(selCountry))
            setEditCountry(selCountry.code)
            //setRules(data.country.instructions)
            setValue('rules', data.country.instructions)
            //getTableData()
            setPopupLoader(false)

        } catch (err) {
            console.error('fetchData:', err);
            setOpenFail(true)
            setTextFail('The server encounted an error and could not complete your request.')

        }     

    }

    //form submit

    const onSubmit = (data) => {

        console.log('submit data', data, editCountry)

        if(editId === 0){

            const dataRules = {
                countryCode: selCountryCode.code,
                instructions: data.rules
            }

            dataPost(dataRules);

        }
        else{

            const dataRules = {
                id:editCountry,
                countryCode: selCountryCode.code,
                instructions: data.rules
            }

            dataPost(dataRules);

        }


    }

    //new data post

    const dataPost = async(dataRules) => {

        console.log('data post', dataRules, 'xx', editId)
        setBtnLoading(true)

        try {

            const res = await axiosInstance.put(`/instruction_build`, dataRules)

            let result = res.data.result;
            let error = res.data.error;

            setUploading(false)
            setOpenAddRules(false)
            getTableData()
            setOpenSuccess(true)
            setTextSuccess(result.message)
            reset({rules:'', country:''})   
            setSelCountry('') 
            setBtnLoading(false)  

        } catch (err) {
            console.error('fetchData:', err);
            setBtnLoading(false)  
            setOpenFail(true)
            setTextFail('The server encounted an error and could not complete your request.')
        }         

    }

    useEffect(()=>{
        console.log('selCountryCode--',selCountryCode)
    },[selCountryCode])

    return(
        <div>
            <Modal
                open={openAddRules}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-secret-key']}`}> 
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={ModalStyle['modal-container']}>
                            <div className={ModalStyle['content-wrapper']}>
                                <>
                                    <Typography id="modal-modal-title" align='center' className={ModalStyle['modal-Header1']} variant="text24" component="h2">
                                        {editId === 0 ? 'Add new country and rules' : 'Edit country and rules'} 
                                    </Typography>
                                    <FormControl variant="standard" fullWidth  margin="normal" className='modal-mt-12 form-control modal-mb-5' >
                                        <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                            Country
                                        </InputLabel>
                                        { popupLoader ? (
                                            <SklInputPopup/>
                                        ):(
                                        <>
                                        <Select
                                            id='country'
                                            className='w-modal-full select-modal type-filter-select'
                                            value={selCountry}
                                            input={<OutlinedInput />}
                                            displayEmpty
                                            renderValue={(selCountry) => {
                                                if (!selCountry) {
                                                return <span className='text-sonicSilver'>-Select country-</span>;
                                                }
                                                return JSON.parse(selCountry).name;
                                            }}
                                            inputProps={{ 'aria-label': 'Country' }}
                                            {...register('country',{ required: editId === 0  ? true : !selCountry.length > 0 ? true : false , onChange: (e) => setSelCountry(e.target.value), x:console.log('selCountry xxx',selCountry) })} 
                                        >
                                            { countryList.length > 0 && countryList.map((filter, index) => (
                                                <MenuItem 
                                                    className='country-down-item'
                                                    key={index} 
                                                    value={JSON.stringify(filter)} 
                                                >
                                                    {filter.name}
                                                </MenuItem>
                                            ))}
                                            
                                        </Select>
                                        {errors.country && <p className='err-msg'>Country is required</p>}
                                        </>
                                        )}
                                    </FormControl>
                                    <FormControl variant="standard" fullWidth  margin="normal" className='modal-mt-0 form-control modal-mb-5' >
                                        <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                            Rules
                                        </InputLabel>
                                        { popupLoader ? (
                                        <Skeleton animation="wave"  className='modal-rules-cus-skeleton'></Skeleton>
                                        ):(
                                        <>
                                            <OutlinedInput 
                                                id='rules'
                                                placeholder='Enter rules'
                                                //value={rules}
                                                autoComplete="off" 
                                                type='text'
                                                multiline
                                                rows={7}
                                                className='modal-input multiple-line'
                                                {...register('rules',{ required: true })} 
                                            />    
                                        {errors.rules && <p className='err-msg'>Rules are required</p>}
                                        </>
                                        )}
                                    </FormControl>
                                </>
                            </div>
                            <div className={ModalStyle['footer-wrapper-center']}>
                                <ButtonSecondary btnName="Cancel" variant={'primaryBtn'} classes='modal-button-12' type="button" onClick={cancel} disabled={uploading}/>
                                {/* <ButtonSecondary btnName={editId === 0? "Add":"Save"} variant={'secondaryBtn'} classes='modal-button-12' type="submit" disabled={uploading}/> */}
                                <ButtonSecondaryLoader variant={'secondaryBtn'} type='submit' classes='modal-button-12' btnName={editId === 0? "Add":"Save"} btnLoading={btnLoading} disabled={uploading}/>
                            </div>
                        </div>    
                    </form>
                </Box>
            </Modal>
        </div> 
    );
}

export default RulesAddModal;