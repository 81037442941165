import React, {useEffect, useState} from 'react';
import { useForm, Controller, set } from "react-hook-form";
import axios from "axios";
import Cookies from 'js-cookie';
import { useBaseUrlContext } from '../../context/Base_url';
import { useAuthContext } from '../../context/Auth';
import useAxiosInstance from '../../api/interceptor';

import {Box, Typography, Modal, FormControl, OutlinedInput, InputLabel, MenuItem, Select, Skeleton, Checkbox} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';
import SklInputPopup from '../skeletons/Skl_input_popup';
import ButtonSecondaryLoader from '../button/Btn_secondary_loader';

import ModalStyle from './Modal_styles.module.scss';
import './Modal_styles.scss';

import CheckIcon from '../../assets/tick-select.svg'; 
import UncheckIcon from '../../assets/tick-notselect.svg'; 

//const BaseUrl = 'https://docupath.app';


const RulesAddModal = ({openAddOrgRules, setOpenAddOrgRules, editId, getTableDataOrg, setOpenFail, setTextFail, setOpenSuccess, setTextSuccess}) =>{

    const { register, handleSubmit, control, formState: { errors }, reset, setValue } = useForm();
    const [uploading, setUploading] = useState(false)
    const [orgList, setOrgList] = useState([])
    const [selOrg, setSelOrg] = useState([])
    const [selOrgCode, setSelOrgCode] = useState([])
    const [popupLoader, setPopupLoader] = useState(false)
    const [orgRestructure, setOrgRestructure] = useState([])
    const {BaseUrl} = useBaseUrlContext()
    const {tokenReview, popupAction} = useAuthContext()
    const axiosInstance = useAxiosInstance();
    const [btnLoading, setBtnLoading] = useState(false)

    const newSearchField = (fieldName) => {

        return fieldName.reduce((acc, { id, orgName, subOrganisations}) => {
            // Add the main org object
            acc.push({ id, orgName, parentOrgId:null ,type:'mainOrg'});
          
            // Add each sub org object
            subOrganisations.forEach(({ id, orgName, parentOrgId}) => {
              acc.push({ id, orgName, parentOrgId, type:'subOrg' });
            });
          
            return acc;
        }, []);

    }

    //create new array structure for orgs

    const newOrgStructure = (orgs) => {

        const transformedArray = orgs.reduce((acc, org) => {
            acc[org.id] = org.subOrganisations.map(sub => sub.id)
            return acc;
        }, {})
        
        console.log('transformedArray', transformedArray)
        setOrgRestructure(transformedArray)

    }

    useEffect(()=>{

        console.log('orgList', orgList)

    },[orgList])

    useEffect(()=>{
        if(openAddOrgRules){
            setUploading(false)
            getorgList()

            if(editId !== 0 ){
                editRules()
            }
        }
    },[openAddOrgRules])

    //get selected countries code ###

    useEffect(()=>{

        console.log('selectedCountry',selOrg)
        if(selOrg){
            setSelOrgCode(selOrg.id)
        }

        
    },[selOrg])

    //cancel ###

    const cancel = () =>{

        setOpenAddOrgRules(false)
        reset({rules:'', organization:''})
        setSelOrg('')

    }

    //get org list ###

    const getorgList = async() =>{

        try {

            const res = await axiosInstance.get(`/organization`)
                
                let data = res.data.result;
                let error = res.data.error;

                console.log('organization', data.organizations)
                const newOrgArray =  newSearchField(data.organizations)
                const newOrgStrucArray = newOrgStructure(data.organizations)
                console.log('newOrgArray >', newOrgArray)
                setOrgList(newOrgArray)

            } catch (err) {
                console.error('fetchData:', err);
            }     

    }


    //get rules data

    const editRules = async() => {


        try {
            setPopupLoader(true)

            const res = await axiosInstance.get(`/organization_instructions?instructionId=${editId}`)
              
            let data = res.data.result;
            let error = res.data.error;

            
            console.log('organization_instructions update', res.data.result)
            //setSelOrg(data.instruction.id)
            const arrayId = data.instruction.organizations &&  data.instruction.organizations.map(item => item.id);
            console.log('arrayId', arrayId)
            setSelOrg([...selOrg, ...arrayId])
            setValue('rules', data.instruction.instructions)
            setValue('organization', [...selOrg, ...arrayId] )
            setPopupLoader(false)

        } catch (err) {
            console.error('fetchData:', err);

        }     

    }

    useEffect(()=>{
        console.log('selOrg arrayId', selOrg)
    },[selOrg])

    //form submit

    const onSubmit = (data) => {

        console.log('submit data', JSON.stringify(data.organization), 'xx', editId)
        if(editId === 0){

            const dataRules = {
                orgIds: selOrg,
                instructions: data.rules,
            }

            dataPost(dataRules);

        }
        else{

            const dataRules = {
                orgIds: selOrg,
                instructions: data.rules,
                instructionId: editId
            }

            dataPost(dataRules);

        }

    }

    //new data post ###

    const dataPost = async(dataRules) => {

        console.log('data post', dataRules, selOrg)
        setBtnLoading(true)

        try {

            //const orgIdList = data.organization.map(org => org.id)
                
            const res = await axiosInstance.put(`/organization_instructions`, dataRules)
            //console.log(res)
            let result = res.data.result;
            let error = res.data.error;

            setUploading(false)
            setOpenAddOrgRules(false)
            getTableDataOrg()
            setOpenSuccess(true)
            setTextSuccess(result.message)
            reset({rules:'', organization:''}) 
            setBtnLoading(false)
            setSelOrg('')

        } catch (err) {
            console.error('fetchData:', err);
            setBtnLoading(false)
            setOpenFail(true)
            setTextFail("The server encounted an error and could not complete your request.")
        }         

    }

    useEffect(()=>{
        console.log('selOrgCode--',selOrgCode)
    },[selOrgCode])

    //find selected organization in restructured array

    const findSelectedOrg = (orgs, checkedVal) => {

        console.log('findSelectedOrg', orgs, checkedVal)

        for (const [key, values] of Object.entries(orgs)) {
            if (values.includes(checkedVal)) {
              return { key, values };
            }
          }
          return null; // If the value is not found
          
    }

    //find selected sub organization in restructured array

    const findSelectedSubOrg = (orgs, checkedVal) => {

        const entry = Object.entries(orgs).find(([key, values]) => values.includes(checkedVal));
        return entry ? { key: entry[0], values: entry[1] } : null;

    };

    const handleSelect = (event) => {

        //console.clear();
        console.log('handleSelect ', event.target.value, event)

        const {
          target: { value },
        } = event;

        const isChecked = selOrg.length < value.length 
        let isParent;
        let unCheckOrg;

        if(!isChecked){
            unCheckOrg = selOrg && selOrg.filter(org1 => !value.some(org2 => org1=== org2));
            console.log('removeOrg ', unCheckOrg)
            isParent = Object.keys(orgRestructure).includes(unCheckOrg.at(-1))
        }
        else{
            isParent = Object.keys(orgRestructure).includes(value.at(-1))
        }


        if(isParent){


            if(isChecked){
                let getChild = orgRestructure[value.at(-1)]
                console.log('newSelection ','isChecked', isChecked, isParent, 'value',value, 'getChild',getChild)
                //setSelOrg([...selOrg, value.at(-1), ...getChild])
                const newValues = [value.at(-1), ...getChild].filter(v => !selOrg.includes(v));
                setSelOrg([...selOrg, ...newValues]);
            }
            else{
                let removeUnchecks = [...orgRestructure[unCheckOrg.at(-1)], unCheckOrg.at(-1)]
                const removeOrg = selOrg && selOrg.filter(org1 => !removeUnchecks.some(org2 => org1 === org2));
                console.log('newSelection else','isChecked', isChecked, 'removeOrg', removeOrg, 'removeUnchecks',removeUnchecks)
                setSelOrg(removeOrg)
            }
            

        }
        else{

            const selectedSubOrg =  findSelectedOrg(orgRestructure, unCheckOrg?.at(-1))
            console.log('selectedSubOrg', selectedSubOrg, orgRestructure, unCheckOrg, unCheckOrg?.at(-1))

            if(isChecked){

                let selOrgDuplicate = [...selOrg];

                const selectedSubOrgItem =  findSelectedSubOrg(orgRestructure, value?.at(-1))
                console.log('selectedSubOrgItem', selectedSubOrgItem);
                selOrgDuplicate.push(value?.at(-1))

                const otherChilds = selectedSubOrgItem.values.filter(item => item !== value?.at(-1))
                console.log('otherChilds', otherChilds);

                const compareArray = otherChilds.every(value => {
                    console.log('ccc', value, selOrgDuplicate, 'xxx', selOrgDuplicate.includes(value)); 
                    return selOrgDuplicate.includes(value)
                })

                console.log('compareArray', compareArray);
                if (compareArray) {
                    const newValues = [value?.at(-1), selectedSubOrgItem.key].filter(v => !selOrg.includes(v));
                    setSelOrg([...selOrg, ...newValues]);
                } else {
                    const newValues = [value?.at(-1)].filter(v => !selOrg.includes(v));
                    setSelOrg([...selOrg, ...newValues]);
                }
                //compareArray ? setSelOrg([...selOrg, value?.at(-1), selectedSubOrgItem.key]) : setSelOrg([...selOrg, value?.at(-1)])

            }
            else{

                let removeUnchecks = selectedSubOrg ? [unCheckOrg.at(-1), selectedSubOrg.key] : []
                const removeOrg = selOrg && selOrg.filter(org1 => !removeUnchecks.some(org2 => org1 === org2));
                console.log('newSelection else sub' ,'isChecked', isChecked, 'removeOrg', removeOrg, 'removeUnchecks',removeUnchecks)
                setSelOrg(removeOrg)
            }
        }

    };

    return(
        <div>
            <Modal
                open={openAddOrgRules}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-secret-key']}`}> 
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={ModalStyle['modal-container']}>
                            <div className={ModalStyle['content-wrapper']}>
                                <>
                                    <Typography id="modal-modal-title" align='center' className={ModalStyle['modal-Header1']} variant="text24" component="h2">
                                        {editId === 0 ? 'Add new organization and rules' : 'Edit organization and rules'} 
                                    </Typography>
                                    <FormControl variant="standard" fullWidth  margin="normal" className='modal-mt-12 form-control modal-mb-5' >
                                        <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                            Organization
                                        </InputLabel>
                                        { popupLoader ? (
                                            <SklInputPopup/>
                                        ):(
                                        <>
                                        <Controller
                                            name="organization-spec"
                                            control={control}
                                            defaultValue={[]}
                                            rules={{
                                              validate: value => {
                                                console.log('Validating value:', value);
                                                return selOrg.length > 0 || 'Organization is required';
                                              }
                                            }}
                                            //rules={{ validate: value => value.length > 0 || 'Organization is required' }}
                                            render={({ field, fieldState: { error } }) => (
                                            <>
                                            <Select
                                                {...field}
                                                id='organization'
                                                className='w-modal-full select-modal type-filter-select'
                                                multiple
                                                value={selOrg ? selOrg : []}
                                                onChange={(e) => {
                                                    handleSelect(e);
                                                    field.onChange(e);
                                                }}
                                                input={<OutlinedInput />}
                                                displayEmpty
                                                renderValue={(selOrg) => {
                                                    if (selOrg.length < 1) {
                                                        return <span className='text-sonicSilver'>-Select organization-</span>;
                                                    } else {
                                                        const selectedOrgs = orgList.filter((org) => selOrg.includes(org.id)).map((org) => org.orgName);
                                                        return selectedOrgs.join(', ');
                                                    }
                                                }}
                                                inputProps={{ 'aria-label': 'organization' }}
                                            >
                                                {orgList.map((filter) => (
                                                <MenuItem
                                                    className={`country-down-item org-specific item-multi ${filter.parentOrgId === null ? 'mainOrg' : 'subOrg'}`}
                                                    key={filter.id}
                                                    value={filter.id}
                                                >
                                                    <Checkbox 
                                                        checked={selOrg?.indexOf(filter.id) > -1} 
                                                        checkedIcon={<img src={CheckIcon} alt='checked' className='w-6'/> }
                                                        icon={<img src={UncheckIcon} alt='uncheck' className='w-6'/> }
                                                    />
                                                    {filter.orgName}
                                                </MenuItem>
                                                ))}
                                            </Select>
                                            {error && <span className='err-msg'>{error.message}</span>}
                                            </>
                                            )}
                                        />
                                       
                                        </>
                                        )}
                                    </FormControl>
                                    <FormControl variant="standard" fullWidth  margin="normal" className='modal-mt-0 form-control modal-mb-5' >
                                        <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                            Rules
                                        </InputLabel>
                                        { popupLoader ? (
                                        <Skeleton animation="wave"  className='modal-rules-cus-skeleton'></Skeleton>
                                        ):(
                                        <>
                                            <OutlinedInput 
                                                id='rules'
                                                placeholder='Enter rules'
                                                //value={rules}
                                                autoComplete="off" 
                                                type='text'
                                                multiline
                                                rows={7}
                                                className='modal-input multiple-line'
                                                {...register('rules',{ required: true })} 
                                            />    
                                        {errors.rules && <p className='err-msg'>Rules are required</p>}
                                        </>
                                        )}
                                    </FormControl>
                                </>
                            </div>
                            <div className={ModalStyle['footer-wrapper-center']}>
                                <ButtonSecondary btnName="Cancel" variant={'primaryBtn'} classes='modal-button-12' type="button" onClick={cancel} disabled={uploading}/>
                                {/* <ButtonSecondary btnName={editId === 0? "Add":"Save"} variant={'secondaryBtn'} classes='modal-button-12' type="submit" disabled={uploading}/> */}
                                <ButtonSecondaryLoader variant={'secondaryBtn'} type='submit' classes='modal-button-12' btnName={editId === 0? "Add":"Save"} btnLoading={btnLoading} disabled={uploading}/>
                            </div>
                        </div>    
                    </form>
                </Box>
            </Modal>
        </div> 
    );
}

export default RulesAddModal;